// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function ListNumbersIcon(props: Props) {
    return (
        <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m3.1484 0.61719-0.20508 0.080078-1.7578 0.69336v1.166l0.81641-0.31641v2.5176h1.1465v-4.1406zm2.0605 1.2754v1.5h10.195v-1.5h-10.195zm-2.9336 4c-0.75292 0-1.2094 0.40806-1.4512 0.70703l-0.080078 0.097656 0.69531 0.80859 0.11328-0.12695c0.18907-0.20984 0.3603-0.36523 0.63281-0.36523 0.20979 0 0.32812 0.10865 0.32812 0.31445 0 0.15568-0.065752 0.29661-0.22461 0.45312l-1.3105 1.3027v0.88672h2.7969v-1.0977h-1.0859l0.41211-0.41797c0.38859-0.39447 0.5918-0.73378 0.5918-1.2148 0-0.39513-0.14019-0.73934-0.39258-0.97852-0.25239-0.23918-0.60922-0.36914-1.0254-0.36914zm2.9336 1.1719v1.5h10.195v-1.5h-10.195zm-3.0332 4.0371c-0.63225 1e-6 -1.0803 0.26534-1.377 0.61524l-0.082031 0.097656 0.68555 0.79102 0.11328-0.13672c0.13523-0.16244 0.33391-0.29883 0.625-0.29883 0.12654 0 0.22422 0.030982 0.28125 0.070312 0.057028 0.03933 0.082031 0.07679 0.082031 0.1543 0 0.07719-0.026489 0.11707-0.09375 0.16016-0.067261 0.043089-0.18134 0.074218-0.31836 0.074218h-0.56836v1.0547h0.5625c0.15469 0 0.29214 0.032468 0.375 0.080078 0.082856 0.04761 0.11133 0.091399 0.11133 0.16602 0 0.0882-0.029211 0.13011-0.097656 0.17383s-0.18392 0.074218-0.32031 0.074218c-0.25468 0-0.48753-0.10297-0.66406-0.28906l-0.11328-0.11914-0.67578 0.78125 0.082031 0.097657c0.3191 0.38577 0.82059 0.59766 1.4121 0.59766 0.38607 0 0.76665-0.093 1.0605-0.29688 0.2939-0.20389 0.49609-0.53216 0.49609-0.94922 0-0.38586-0.2755-0.65178-0.60352-0.84375 0.28745-0.19641 0.52734-0.45857 0.52734-0.83398 0-0.36649-0.16459-0.68417-0.43555-0.89648-0.27095-0.21231-0.64079-0.32422-1.0645-0.32422zm3.0332 1.2832v1.5h10.195v-1.5h-10.195z"
                fill="currentColor"
            />
        </svg>
    );
}
ListNumbersIcon.displayName = "ListNumbersIcon";
