import React from "react";

export const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="12" height="2" rx="1" fill="black" />
            <rect y="4" width="12" height="2" rx="1" fill="black" />
            <rect y="8" width="12" height="2" rx="1" fill="black" />
        </svg>
    );
};

HamburgerIcon.displayName = "HamburgerIcon";
