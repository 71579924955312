import React from "react";

export function LandscapeFilterIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 18 12" fill="none" {...props}>
            <rect width="17.9998" height="12" fill="#666666" />
            <rect x="0.5" y="0.5" width="16.9998" height="11" stroke="currentColor" strokeOpacity="0.2" />
        </svg>
    );
}
LandscapeFilterIcon.displayName = "LandscapeFilterIcon";
