import React from "react";

export function PortraitFilterIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 12 18" fill="none" {...props}>
            <rect width="12" height="18" fill="#666666" />
            <rect x="0.5" y="0.5" width="11" height="17" stroke="currentColor" strokeOpacity="0.2" />
        </svg>
    );
}
PortraitFilterIcon.displayName = "PortraitFilterIcon";
