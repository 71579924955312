import React from "react";

export function PremiumFinishIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M11.5 8L12.5967 5.59667L15 4.5L12.5967 3.40333L11.5 1L10.4033 3.40333L8 4.5L10.4033 5.59667L11.5 8ZM7.125 8.875L5.66667 5.66667L4.20833 8.875L1 10.3333L4.20833 11.7917L5.66667 15L7.125 11.7917L10.3333 10.3333L7.125 8.875Z"
                fill="currentColor"
            />
        </svg>
    );
}
PremiumFinishIcon.displayName = "PremiumFinishIcon";
