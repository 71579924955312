import React from "react";

export function BleedLineIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect opacity="0.1" width="24" height="24" rx="12" fill="#3B5FF3" />
            <rect
                x="19.75"
                y="11.75"
                width="0.5"
                height="15.5"
                rx="0.25"
                transform="rotate(90 19.75 11.75)"
                fill="white"
                stroke="#3B5FF3"
                strokeWidth="0.5"
                strokeDasharray="1 1"
            />
        </svg>
    );
}
BleedLineIcon.displayName = "BleedLineIcon";
