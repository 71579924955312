import React, { HTMLProps } from "react";
import classNames from "classnames";
import { StandardSheetHeader } from "./StandardSheetHeader";
import { ContentPanel } from "./ContentPanel";

interface Props extends HTMLProps<HTMLDivElement> {
    hidebackButton?: boolean;
    isSelected: boolean;
    onBackClick?: () => void;
    onCloseClick: () => void;
    sheetTitle?: string;
    backButtonAriaLabel: string;
    closeButtonAriaLabel: string;
}

export const SheetPanel = (props: Props) => {
    const {
        children,
        className,
        isSelected,
        onCloseClick,
        sheetTitle,
        onBackClick,
        backButtonAriaLabel,
        closeButtonAriaLabel
    } = props;

    return (
        <ContentPanel className={classNames("sheet-panel", className)} isSelected={isSelected}>
            <StandardSheetHeader
                className="sheet-panel-header"
                pb={"5"}
                onBackClick={onBackClick}
                onCloseClick={onCloseClick}
                sheetTitle={sheetTitle}
                backButtonAriaLabel={backButtonAriaLabel}
                closeButtonAriaLabel={closeButtonAriaLabel}
            />
            {children}
        </ContentPanel>
    );
};

SheetPanel.displayName = "SheetPanel";
