import React, { HTMLProps, useRef, useState, useLayoutEffect, forwardRef, useEffect } from "react";
import classNames from "classnames";
import { FlexBox, Icon, Span } from "@vp/swan";
import styles from "./SideScroller.module.scss";

interface Props extends HTMLProps<HTMLDivElement> {
    ariaLabelScrollLeft: string;
    ariaLabelScrollRight: string;
    scrollSnap?: boolean;
    dataTestId?: string;
    leftButtonClassName?: string;
    rightButtonClassName?: string;
    // Increases the width of the gradient behind the arrow buttons for better visibility
    expandScrollIndicators?: boolean;
}

export const SideScroller = forwardRef(
    (
        {
            children,
            ariaLabelScrollLeft,
            ariaLabelScrollRight,
            className,
            scrollSnap,
            dataTestId,
            leftButtonClassName,
            rightButtonClassName,
            expandScrollIndicators = false
        }: Props,
        ref: any
    ) => {
        const containerRef = useRef<HTMLDivElement | null>(null);
        const contentsRef = useRef<HTMLDivElement | null>(null);
        const leftButtonRef = useRef<HTMLButtonElement | null>(null);
        const rightButtonRef = useRef<HTMLButtonElement | null>(null);
        const [showLeft, setShowLeft] = useState<boolean>(false);
        const [showRight, setShowRight] = useState<boolean>(false);
        const [currentScroll, setCurrentScroll] = useState<number>(0);

        // we're storing these widths to state instead of relying on refs because it was causing some issues
        // when changing between selected items. re-mounting didn't always pick up the updated refs.
        const [containerWidth, setContainerWidth] = useState<number>(0);
        const [contentsWidth, setContentsWidth] = useState<number>(0);

        useLayoutEffect(() => {
            setContainerWidth(containerRef.current?.clientWidth || 0);
            // on load determine if there's enough content to show the right scroll button
            setShowRight(containerWidth < contentsWidth);
        }, [containerWidth, contentsWidth]);

        const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
            event.persist();
            setShowLeft(event.currentTarget.scrollLeft > 0);
            setShowRight(event.currentTarget.scrollLeft + event.currentTarget.clientWidth + 1 < contentsWidth);
            setCurrentScroll(event.currentTarget.scrollLeft);
        };

        // this isn't inlined so that containerRef is mutable without casting it.
        const setContainerRef = (current: HTMLDivElement | null) => {
            containerRef.current = current;
            setContainerWidth(current?.clientWidth || 0);
            if (ref && current) {
                ref.current = current;
            }
        };

        const setContentsRef = (current: HTMLDivElement | null) => {
            contentsRef.current = current;
            setContentsWidth(current?.scrollWidth || 0);
            if (ref && current) {
                ref.current = current;
            }
        };

        const handleScroll = (moveToRight: boolean) => {
            if (containerRef.current) {
                let scroll = containerWidth;

                if (!moveToRight && currentScroll < containerWidth) {
                    scroll = currentScroll;
                }

                if (moveToRight && contentsWidth - currentScroll - containerWidth < containerWidth) {
                    scroll = contentsWidth - currentScroll - containerWidth;
                }

                containerRef.current.scrollBy({
                    top: 0,
                    left: (moveToRight ? 1 : -1) * scroll,
                    behavior: "smooth"
                });
            }
        };

        useEffect(() => {
            // handle focus when the right arrow button is removed and is the focused element
            if (!showRight && document.activeElement === rightButtonRef.current) {
                // move focus to the last child of the scroller contents
                const lastChild = contentsRef.current?.lastElementChild as HTMLElement;
                lastChild?.focus();
            }
        }, [showRight]);

        useEffect(() => {
            // handle focus when the left arrow button is removed and is the focused element
            if (!showLeft && document.activeElement === leftButtonRef.current) {
                // move focus to the first child of the scroller contents
                const firstChild = contentsRef.current?.firstElementChild as HTMLElement;
                firstChild?.focus();
            }
        }, [showLeft]);

        return (
            <div className={styles.sideScroller}>
                <div
                    ref={setContainerRef}
                    className={classNames(styles.sideScrollContainer, {
                        [styles.sideScrollContainerSnap]: scrollSnap
                    })}
                    onScroll={onScroll}
                >
                    {/* gradient that also extends the clickable area around scroll button */}
                    {/* skip in tab order since scroll button already receives focus */}
                    <Span
                        className={classNames(styles.sideScrollIndicator, styles.sideScrollIndicatorLeft, {
                            [styles.sideScrollIndicatorShow]: showLeft,
                            [styles.expandedSideScrollIndicator]: expandScrollIndicators
                        })}
                    />
                    <Span
                        className={classNames(styles.sideScrollIndicator, styles.sideScrollIndicatorRight, {
                            [styles.sideScrollIndicatorShow]: showRight,
                            [styles.expandedSideScrollIndicator]: expandScrollIndicators
                        })}
                    />

                    {/* scroll buttons */}
                    <button
                        ref={leftButtonRef}
                        className={classNames(
                            styles.sideScrollButton,
                            styles.sideScrollButtonLeft,
                            {
                                [styles.sideScrollButtonShow]: showLeft
                            },
                            leftButtonClassName
                        )}
                        onClick={() => handleScroll(false)}
                        aria-label={ariaLabelScrollLeft}
                    >
                        <Icon iconType="chevronLeft" size="16p" />
                    </button>
                    <button
                        ref={rightButtonRef}
                        className={classNames(
                            styles.sideScrollButton,
                            styles.sideScrollButtonRight,
                            {
                                [styles.sideScrollButtonShow]: showRight
                            },
                            rightButtonClassName
                        )}
                        onClick={() => handleScroll(true)}
                        aria-label={ariaLabelScrollRight}
                    >
                        <Icon iconType="chevronRight" size="16p" />
                    </button>

                    <FlexBox className={className} ref={setContentsRef} data-testid={dataTestId}>
                        {children}
                    </FlexBox>
                </div>
            </div>
        );
    }
);

SideScroller.displayName = "SideScroller";
