import React, { forwardRef, HTMLProps, Ref } from "react";
import Measure from "react-measure";
import classNames from "classnames";
import styles from "./Sheet.module.scss";

interface Props extends HTMLProps<HTMLDivElement> {
    /**
     * Prop for adding an accessible translated aria label string
     */
    ariaLabel: string;

    /** Whether the sheet should be displayed or not */
    hideSheet?: boolean;

    reportSizeChange?: (rect: DOMRect) => void;

    /** Whether it should has rounded borders */
    removeRoundedBorder?: boolean;
    /**
     * Limit the height of the sheet
     * @default true
     * */
    limitHeight?: boolean;
    /**
     * Take up the full screen
     * @default false
     * */
    fullHeight?: boolean;
    /**
     * Remove overflow from sheet
     * Used to prevent double scrollbar when inner content is scrollable
     * @default false
     * */
    noOverflow?: boolean;
    /**
     * A prop with the option to change the default role being a 'dialog'
     * */
    role?: string;
}

const Sheet = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
    const {
        reportSizeChange,
        children,
        ariaLabel,
        hideSheet,
        className,
        removeRoundedBorder,
        limitHeight,
        fullHeight,
        noOverflow,
        role = "dialog",
        ...restOfProps
    } = props;

    return (
        <Measure
            bounds
            onResize={contentRect => {
                if (reportSizeChange) {
                    reportSizeChange(contentRect.entry);
                }
            }}
        >
            {({ measureRef }) => (
                <div
                    role={role}
                    aria-label={ariaLabel}
                    ref={measureRef}
                    className={classNames(
                        "sheet-component",
                        styles.sheetComponent,
                        {
                            [styles.sheetComponentFullHeight]: fullHeight,
                            "sheet-component-full-height": fullHeight,
                            [styles.sheetComponentLimitHeight]: limitHeight && !fullHeight,
                            "sheet-component-limit-height": limitHeight && !fullHeight,
                            [styles.sheetComponentHidden]: hideSheet,
                            "sheet-component-hidden": hideSheet,
                            [styles.sheetComponentNoBorderRadius]: removeRoundedBorder,
                            "sheet-component-no-border-radius": removeRoundedBorder,
                            [styles.noOverflow]: noOverflow,
                            "no-overflow": noOverflow
                        },
                        className
                    )}
                    data-testid="sheet"
                    {...restOfProps}
                >
                    {children}
                </div>
            )}
        </Measure>
    );
});

export { Sheet };
Sheet.displayName = "Sheet";
