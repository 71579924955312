import React from "react";

export function FlexibilityIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 12 10" fill="none" {...props}>
            <circle cx="1" cy="1" r="1" fill="currentColor" />
            <circle cx="1" cy="5" r="1" fill="currentColor" />
            <circle cx="1" cy="9" r="1" fill="currentColor" />
            <rect x="3" width="9" height="2" rx="1" fill="currentColor" />
            <rect x="3" y="4" width="9" height="2" rx="1" fill="currentColor" />
            <rect x="3" y="8" width="9" height="2" rx="1" fill="currentColor" />
        </svg>
    );
}
FlexibilityIcon.displayName = "FlexibilityIcon";
