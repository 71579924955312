import React from "react";

export function StretchIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx="2" cy="2" r="2" fill="currentColor" />
            <circle cx="2" cy="8" r="2" fill="currentColor" />
            <circle cx="2" cy="14" r="2" fill="currentColor" />

            <circle cx="8" cy="2" r="2" fill="currentColor" />
            <circle cx="8" cy="14" r="2" fill="currentColor" />

            <circle cx="14" cy="2" r="2" fill="currentColor" />
            <circle cx="14" cy="8" r="2" fill="currentColor" />
            <circle cx="14" cy="14" r="2" fill="currentColor" />
            <rect x="2" y="2" width="12" height="12" fill="none" stroke="currentColor" strokeWidth="1" />
        </svg>
    );
}
StretchIcon.displayName = "StretchIcon";
