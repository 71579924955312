import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function AddTableColumnRightIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M13 14C13.5523 14 14 13.5523 14 13L14 3C14 2.44772 13.5523 2 13 2L10 2C9.44771 2 9 2.44772 9 3L9 13C9 13.5523 9.44772 14 10 14L13 14Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path d="M2 8L6 8M4 10L4 6" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
AddTableColumnRightIcon.displayName = "AddTableColumnRightIcon";
