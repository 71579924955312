import React from "react";

export function EditTextIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.66 13.5h10.69c1.75 0 2.65-.87 2.65-2.57V3.06C16 1.36 15.1.5 13.35.5H2.66C.89.5 0 1.36 0 3.06v7.87c0 1.7.89 2.57 2.66 2.57Zm.04-1.42c-.8 0-1.26-.42-1.26-1.25V3.16c0-.83.45-1.25 1.26-1.25h10.6c.8 0 1.26.42 1.26 1.25v7.67c0 .83-.45 1.25-1.26 1.25H2.7Zm.9-6.96h.77c.24 0 .38-.14.38-.37V4c0-.24-.14-.38-.38-.38H3.6c-.22 0-.37.14-.37.38v.75c0 .23.15.37.37.37Zm2.67 0h.76c.24 0 .38-.14.38-.37V4c0-.24-.14-.38-.38-.38h-.76c-.23 0-.38.14-.38.38v.75c0 .23.15.37.38.37Zm2.66 0h.77c.24 0 .38-.14.38-.37V4c0-.24-.14-.38-.38-.38h-.77c-.23 0-.38.14-.38.38v.75c0 .23.15.37.38.37Zm2.68 0h.76c.23 0 .38-.14.38-.37V4c0-.24-.15-.38-.38-.38h-.76c-.25 0-.39.14-.39.38v.75c0 .23.14.37.4.37Zm-8 2.61h.76c.24 0 .38-.13.38-.37v-.75c0-.22-.14-.37-.38-.37H3.6c-.22 0-.37.15-.37.37v.75c0 .24.15.37.37.37Zm2.66 0h.76c.24 0 .38-.13.38-.37v-.75c0-.22-.14-.37-.38-.37h-.76c-.23 0-.38.15-.38.37v.75c0 .24.15.37.38.37Zm2.66 0h.77c.24 0 .38-.13.38-.37v-.75c0-.22-.14-.37-.38-.37h-.77c-.23 0-.38.15-.38.37v.75c0 .24.15.37.38.37Zm2.68 0h.76c.23 0 .38-.13.38-.37v-.75c0-.22-.15-.37-.38-.37h-.76c-.25 0-.39.15-.39.37v.75c0 .24.14.37.4.37Zm-7.93 2.62h8.61c.3 0 .46-.16.46-.45v-.59c0-.28-.17-.45-.46-.45h-8.6c-.3 0-.46.17-.46.45v.59c0 .29.16.45.45.45Z"
                fill="currentColor"
            />
        </svg>
    );
}
EditTextIcon.displayName = "EditTextIcon";
