import React from "react";

export function ItemAlignmentTopIcon() {
    return (
        <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m0.82031 0.5a0.625 0.625 0 0 0-0.62695 0.62305 0.625 0.625 0 0 0 0.62305 0.62695l14.178 0.046875a0.625 0.625 0 0 0 0.62695-0.62305 0.625 0.625 0 0 0-0.62305-0.62695l-14.178-0.046875zm1.4297 3.2969c-0.40469 0-0.75 0.34531-0.75 0.75v9.5c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-9.5c0-0.40469-0.34531-0.75-0.75-0.75h-3.5zm7 0c-0.40469 0-0.75 0.34531-0.75 0.75v4.5c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-4.5c0-0.40469-0.34531-0.75-0.75-0.75h-3.5zm-6.5 1.25h2.5v8.5h-2.5v-8.5zm7 0h2.5v3.5h-2.5v-3.5z"
                fill="currentColor"
            />
        </svg>
    );
}
ItemAlignmentTopIcon.displayName = "ItemAlignmentTopIcon";
