import React, { useEffect, useRef, KeyboardEvent, ChangeEvent } from "react";
import classNames from "classnames";
import { scrollToRef } from "@internal/utils-browser";
import { HorizontalDivider } from "../Divider";
import styles from "./ValueContent.module.scss";

export interface Option {
    value: string;
    name: string;
}

interface Props {
    /** The list of options to display */
    options: Option[];
    /** The currently selected option */
    selected: Option;
    /**
     * Allow filtering of valid values / shows input field
     * @default false
     */
    filtering?: boolean;
    /**
     * The checks if the enter input is a valid option
     * Can be used to restrict to a range of values
     * Can be used to restrict to specific values
     */
    isValidInput: (input: string) => boolean;
    /** When a user picks a value call this method
     * User is responsible for connecting this up to a Store / something external that updates the selected parameter
     */
    onChange: (input: Option) => void;

    /** Maximum number of decimal places a user can enter if this is a number */
    maxDecimalPlaces?: number;

    /** For overriding styles */
    className?: string;
    /** Any offset in pixels that needs to be added for the auto scroll, example if there is a search bar
     * @default 0
     */
    scrollOffset?: number;

    displayUnits?: string;

    inputAriaLabel: string;

    currentFilter: string;
    setCurrentFilter: (newFilter: string) => void;

    hasValidInput: boolean;

    setHasValidInput: (isValid: boolean) => void;

    /**
     * Any side-effects that should happen in addition to the standard enter handler - Currently just used for tracking
     */
    onEnterPressed?: () => void;

    /**
     * Any side-effects that should happen in addition to the standard click handler - Currently just used for tracking
     */
    onClick: () => void;
    noContentFoundText: string;
}

function convertToUnits(input: string, units: string): string {
    return `${input}${units}`;
}

/**
 * This provides a select with a free entry text field
 */
export function ValueContent({
    options,
    selected,
    filtering = false,
    isValidInput,
    displayUnits = "",
    onChange,
    maxDecimalPlaces,
    className,
    scrollOffset = 0,
    inputAriaLabel,
    currentFilter,
    setCurrentFilter,
    hasValidInput,
    setHasValidInput,
    onEnterPressed,
    onClick,
    noContentFoundText
}: Props) {
    const selectedOptionRef = useRef<HTMLButtonElement>(null);
    const optionListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedOptionRef.current) {
            scrollToRef(optionListRef, selectedOptionRef, scrollOffset);
        }
    }, [scrollOffset]);

    function handleClick(newOption: Option) {
        onChange(newOption);
        onClick();
    }

    // Assumption made that anything provided in the list is a valid value
    function handleOptionButtonKeyPress(event: KeyboardEvent<HTMLButtonElement>, newOption: Option) {
        if (event.key === "Enter") {
            setValue(newOption.value);
            setCurrentFilter("");
            setHasValidInput(true);
        }
    }

    function handleInputTextChange(event: ChangeEvent<HTMLInputElement>) {
        let text = event.target.value;

        // If this is a number and the maximum decimal places for the input field is set and we're at the max,
        // remove the last digit from the input
        if (
            !Number.isNaN(+text) &&
            text.includes(".") &&
            maxDecimalPlaces &&
            text.split(".")[1].length > maxDecimalPlaces
        ) {
            text = text.slice(0, -1);
        }

        setHasValidInput(isValidInput(text));
        if (filtering) {
            setCurrentFilter(text);
        }
    }

    function handleInputEnterKey(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            setValue(currentFilter);
            setCurrentFilter("");
            onEnterPressed?.();
        }
    }

    function setValue(value: string) {
        setHasValidInput(isValidInput(value));
        if (isValidInput(value)) {
            const newOption = options.find((opt: Option) => opt.value === value);
            if (newOption) {
                onChange(newOption);
            } else if (value) {
                onChange({ value, name: convertToUnits(value, displayUnits) });
            }
        }
        if (filtering) {
            setCurrentFilter(value);
        }
    }

    function doFilteredOptionsExist() {
        return options.filter(option => option.name.indexOf(currentFilter) >= 0).length === 0;
    }

    // AST-73 TODO:  The non-easel classnames (value-content-list, value-content-list-item, etc)
    // are mobile classes coming from strokeWidthSheet.scss and have not been migrated
    return (
        <div className={classNames(className, "easel-valuecontent-pop", styles.easelValuecontentPop)}>
            {filtering ? (
                <div
                    className={classNames("easel-valuecontent-inputcontainer", styles.easelValuecontentInputcontainer)}
                >
                    <input
                        aria-label={inputAriaLabel}
                        value={currentFilter}
                        type="text"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputTextChange(event)}
                        onKeyUp={handleInputEnterKey}
                        className={classNames("easel-valuecontent-input", styles.easelValuecontentInput, {
                            "easel-valuecontent-invalidinput": !hasValidInput && currentFilter,
                            [styles.easelValuecontentInvalidinput]: !hasValidInput && currentFilter
                        })}
                    />
                    <HorizontalDivider
                        className={`easel-valuecontent-horizontaldivider ${styles.easelValuecontentHorizontaldivider}`}
                    />
                </div>
            ) : null}
            <div
                ref={optionListRef}
                className={classNames(
                    "value-content-list",
                    "easel-valuecontent-optionlist",
                    styles.easelValuecontentOptionlist
                )}
            >
                {options.map(option => {
                    const isSelected = selected.value === option.value;
                    return option.name.indexOf(currentFilter) >= 0 ? (
                        <button
                            ref={isSelected ? selectedOptionRef : null}
                            type="button"
                            key={option.name}
                            onClick={() => handleClick(option)}
                            aria-pressed={isSelected}
                            onKeyPress={(event: KeyboardEvent<HTMLButtonElement>) =>
                                handleOptionButtonKeyPress(event, option)
                            }
                            className={classNames(
                                "value-content-list-item",
                                "easel-valuecontent-option",
                                "easel-valuecontent-textdisplay",
                                {
                                    "easel-valuecontent-option-selected": isSelected,
                                    [styles.easelValuecontentOptionSelected]: isSelected
                                },
                                styles.easelValuecontentOption,
                                styles.easelValuecontentTextdisplay
                            )}
                        >
                            {option.name}
                        </button>
                    ) : null;
                })}
                {doFilteredOptionsExist() && !hasValidInput ? <div>{noContentFoundText}</div> : null}
                {doFilteredOptionsExist() && hasValidInput ? (
                    <button
                        type="button"
                        key={currentFilter}
                        onClick={() =>
                            handleClick({ value: currentFilter, name: convertToUnits(currentFilter, displayUnits) })
                        }
                        onKeyPress={(event: KeyboardEvent<HTMLButtonElement>) =>
                            handleOptionButtonKeyPress(event, {
                                value: currentFilter,
                                name: convertToUnits(currentFilter, displayUnits)
                            })
                        }
                        className={classNames(
                            "value-content-list-item",
                            "easel-valuecontent-option",
                            "easel-valuecontent-textdisplay",
                            styles.easelValuecontentOption,
                            styles.easelValuecontentTextdisplay
                        )}
                    >
                        {convertToUnits(currentFilter, displayUnits)}
                    </button>
                ) : null}
            </div>
        </div>
    );
}
ValueContent.displayName = "ValueContent";
