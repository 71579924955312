// This code has been generated via svgr

import React from "react";

export function SelectedCheckIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx="12" cy="12" r="12" fill="currentColor" />
            <path
                d="M7.18749 12.1114L10.1215 15.125L16.8125 8.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SelectedCheckIcon.displayName = "SelectedCheckIcon";
