import React, { useRef } from "react";
import classNames from "classnames";
import styles from "./SmallScreenSheetValueContent.module.scss";

export interface Option {
    value: string;
    name: string;
}

interface Props {
    options: Option[];
    selected: Option;
    onChange: (input: Option) => void;
    className?: string;
}

export function SmallScreenSheetValueContent({ options, selected, onChange, className }: Props) {
    const selectedOptionRef = useRef<HTMLButtonElement>(null);
    const optionListRef = useRef<HTMLDivElement>(null);

    function handleClick(newOption: Option) {
        onChange(newOption);
    }

    return (
        <div className={classNames(className, styles.sheetContentValueContainer)}>
            <div ref={optionListRef} className={styles.sheetOptionList}>
                {options.map(option => {
                    const isSelected = selected.value === option.value;
                    return (
                        <button
                            ref={isSelected ? selectedOptionRef : null}
                            type="button"
                            key={option.name}
                            onClick={() => handleClick(option)}
                            aria-pressed={isSelected}
                            className={classNames(styles.sheetOptionItem, {
                                [styles.selectedSheetOptionItem]: isSelected
                            })}
                        >
                            {option.name}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}
SmallScreenSheetValueContent.displayName = "SmallScreenSheetValueContent";
