import React from "react";
export function DielineIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6 1.75H14V0.25H6V1.75ZM5.75 4V2H4.25V4H5.75ZM2 5.75H4V4.25H2V5.75ZM1.75 14V6H0.25V14H1.75ZM4 14.25H2V15.75H4V14.25ZM5.75 18V16H4.25V18H5.75ZM14 18.25H6V19.75H14V18.25ZM14.25 16V18H15.75V16H14.25ZM18 14.25H16V15.75H18V14.25ZM18.25 6V14H19.75V6H18.25ZM16 5.75H18V4.25H16V5.75ZM14.25 2V4H15.75V2H14.25ZM16 4.25C15.8619 4.25 15.75 4.13807 15.75 4H14.25C14.25 4.9665 15.0335 5.75 16 5.75V4.25ZM19.75 6C19.75 5.0335 18.9665 4.25 18 4.25V5.75C18.1381 5.75 18.25 5.86193 18.25 6H19.75ZM18 15.75C18.9665 15.75 19.75 14.9665 19.75 14H18.25C18.25 14.1381 18.1381 14.25 18 14.25V15.75ZM15.75 16C15.75 15.8619 15.8619 15.75 16 15.75V14.25C15.0335 14.25 14.25 15.0335 14.25 16H15.75ZM14 19.75C14.9665 19.75 15.75 18.9665 15.75 18H14.25C14.25 18.1381 14.1381 18.25 14 18.25V19.75ZM4.25 18C4.25 18.9665 5.0335 19.75 6 19.75V18.25C5.86193 18.25 5.75 18.1381 5.75 18H4.25ZM4 15.75C4.13807 15.75 4.25 15.8619 4.25 16H5.75C5.75 15.0335 4.9665 14.25 4 14.25V15.75ZM0.25 14C0.25 14.9665 1.0335 15.75 2 15.75V14.25C1.86193 14.25 1.75 14.1381 1.75 14H0.25ZM2 4.25C1.0335 4.25 0.25 5.0335 0.25 6H1.75C1.75 5.86193 1.86193 5.75 2 5.75V4.25ZM4.25 4C4.25 4.13807 4.13807 4.25 4 4.25V5.75C4.9665 5.75 5.75 4.9665 5.75 4H4.25ZM14 1.75C14.1381 1.75 14.25 1.86193 14.25 2H15.75C15.75 1.0335 14.9665 0.25 14 0.25V1.75ZM6 0.25C5.0335 0.25 4.25 1.0335 4.25 2H5.75C5.75 1.86193 5.86193 1.75 6 1.75V0.25Z"
                fill="currentColor"
            />
            <line
                x1="14.75"
                y1="7.25"
                x2="14.75"
                y2="12.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                strokeDasharray="2 3"
            />
            <line
                x1="4.75"
                y1="7.25"
                x2="4.75"
                y2="12.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                strokeDasharray="2 3"
            />
            <line
                x1="7.25"
                y1="5.25"
                x2="12.75"
                y2="5.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                strokeDasharray="2 3"
            />
            <line
                x1="7.25"
                y1="15.25"
                x2="12.75"
                y2="15.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                strokeDasharray="2 3"
            />
        </svg>
    );
}
DielineIcon.displayName = "DielineIcon";
