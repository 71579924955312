import React, { ButtonHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";
import { Typography, Button as SwanButton } from "@vp/swan";
import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { Button } from "../Buttons";
import styles from "./GenericHeaderButton.module.scss";

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
    label: string;
    icon: React.ReactNode;
    skin?: "unstyled" | "tertiary";
}

export const GenericHeaderButton = forwardRef<HTMLButtonElement, Props>(
    ({ icon, label, className, skin = "tertiary", ...rest }, ref) => {
        return (
            <>
                <DesktopExperience>
                    <SwanButton
                        iconPosition="left"
                        className={classNames(styles.genericHeaderButtonDesktop, className)}
                        ref={ref}
                        skin={skin}
                        {...rest}
                    >
                        {icon}
                        <Typography>{label}</Typography>
                    </SwanButton>
                </DesktopExperience>
                <MobileExperience>
                    <Button className={className} {...rest}>
                        {icon}
                        <Typography>{label}</Typography>
                    </Button>
                </MobileExperience>
            </>
        );
    }
);

GenericHeaderButton.displayName = "GenericHeaderButton";
