import React from "react";

export function SquareFilterIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 18 18" fill="none" {...props}>
            <rect width="18" height="18" fill="#666666" />
            <rect x="0.5" y="0.5" width="17" height="17" stroke="currentColor" strokeOpacity="0.2" />
        </svg>
    );
}
SquareFilterIcon.displayName = "SquareFilterIcon";
