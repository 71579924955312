// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function FontFamilyIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path d="M8 3.5L8 13.5" stroke="currentColor" strokeWidth={1.5} />
            <path d="M3 3.52344H13" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path
                d="M2.25 5.52344C2.25 5.93765 2.58579 6.27344 3 6.27344C3.41421 6.27344 3.75 5.93765 3.75 5.52344L2.25 5.52344ZM2.25 3.5L2.25 5.52344L3.75 5.52344L3.75 3.5L2.25 3.5Z"
                fill="currentColor"
            />
            <path
                d="M12.25 5.52344C12.25 5.93765 12.5858 6.27344 13 6.27344C13.4142 6.27344 13.75 5.93765 13.75 5.52344L12.25 5.52344ZM12.25 3.5L12.25 5.52344L13.75 5.52344L13.75 3.5L12.25 3.5Z"
                fill="currentColor"
            />
            <line
                x1={5.75}
                y1={13.75}
                x2={10.25}
                y2={13.75}
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}
FontFamilyIcon.displayName = "FontFamilyIcon";
