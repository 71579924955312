import React from "react";

export function ItemAlignmentBottomIcon() {
    return (
        <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m2.25 0.5c-0.40469 0-0.75 0.34531-0.75 0.75v9.5c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-9.5c0-0.40469-0.34531-0.75-0.75-0.75h-3.5zm0.5 1.25h2.5v8.5h-2.5v-8.5zm6.5 3.75c-0.40469 0-0.75 0.34531-0.75 0.75v4.5c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-4.5c0-0.40469-0.34531-0.75-0.75-0.75h-3.5zm0.5 1.25h2.5v3.5h-2.5v-3.5zm5.2441 6.75-14.178 0.046875a0.625 0.625 0 0 0-0.62305 0.62695 0.625 0.625 0 0 0 0.62695 0.62305l14.178-0.046875a0.625 0.625 0 0 0 0.62305-0.62695 0.625 0.625 0 0 0-0.62695-0.62305z"
                fill="currentColor"
            />
        </svg>
    );
}
ItemAlignmentBottomIcon.displayName = "ItemAlignmentBottomIcon";
