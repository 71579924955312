import React from "react";

export function PdfIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 21 24" fill="none" {...props}>
            <path
                d="M0 3C0 1.34315 1.34315 0 3 0H13.7574C14.553 0 15.3161 0.316071 15.8787 0.87868L20.1213 5.12132C20.6839 5.68393 21 6.44699 21 7.24264V21C21 22.6569 19.6569 24 18 24H3C1.34315 24 0 22.6569 0 21V3Z"
                fill="#FB3B28"
            />
            <path
                d="M21 7.49993H16.4999C14.8431 7.49993 13.4999 6.15679 13.4999 4.49993V0H13.7574C14.5495 0 15.3093 0.313258 15.8711 0.871169L20.1287 5.12877C20.6867 5.69065 21 6.4505 21 7.24264V7.49993Z"
                fill="#CC0400"
            />
            <path
                d="M11 8.61758C11.0709 8.40371 11.1472 7.60684 10.6154 7.50962C10.0305 7.40267 9.28908 8.20328 9.5 9.11224C9.95913 11.0908 12.583 15.1263 15.7737 15.9818C16.4592 16.1656 16.8372 14.949 16.0928 14.752C14.1783 14.2455 7.58424 15.2333 4.92535 17.7462C4.04859 18.5749 4.65942 20.109 5.56347 19.2433C7.05246 17.8175 9.76626 14.0927 11 8.61758Z"
                stroke="white"
                strokeWidth="1.2"
            />
        </svg>
    );
}
PdfIcon.displayName = "PdfIcon";
