import React from "react";
export function Box3DIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2 5.5L10 1L18 5.5M2 5.5V14.5L10 19M2 5.5L10 10M10 19L18 14.5V5.5M10 19V10M18 5.5L10 10"
                stroke="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
}
Box3DIcon.displayName = "Box3DIcon";
