import React from "react";

export function DuplicateProjectIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <rect x="3.5" y="3.32031" width="7" height="9" rx="0.5" fill="white" stroke="black" />
            <rect x="5.5" y="5.32031" width="7" height="9" rx="0.5" fill="white" stroke="black" />
        </svg>
    );
}
DuplicateProjectIcon.displayName = "DuplicateProjectIcon";
