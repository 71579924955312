import React from "react";
import classNames from "classnames";
import { Box, BoxProps } from "@vp/swan";
import { HorizontalScroller } from "../HorizontalScroller";
import styles from "./SmallScreenAddContentToolbar.module.scss";

type SmallScreenAddContentToolbarProps = BoxProps & {
    children?: React.ReactNode[];
    hideSidebar?: boolean;
    loaded?: boolean;
};
export const SmallScreenAddContentToolbar = (props: SmallScreenAddContentToolbarProps) => {
    const { children, hideSidebar, ...rest } = props;

    return (
        <Box
            compactMode
            {...rest}
            className={classNames(styles.smallscreenAddcontentToolbar, {
                [styles.smallscreenAddcontentToolbarHidden]: hideSidebar
            })}
            data-testid="mobileToolbar"
        >
            <HorizontalScroller isDarkToolbar>
                <div
                    className={classNames(styles.smallscreenAddcontentToolbarButtons)}
                    data-testid="smallScreenToolbar"
                >
                    {children}
                </div>
            </HorizontalScroller>
        </Box>
    );
};

SmallScreenAddContentToolbar.displayName = "SmallScreenAddContentToolbar";
