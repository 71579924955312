import classNames from "classnames";
import React, { Ref } from "react";

import styles from "./SvgButton.module.scss";

type SvgButtonProps = React.HTMLAttributes<HTMLDivElement>;

export const SvgButton = React.forwardRef((props: SvgButtonProps, ref: Ref<HTMLDivElement>) => {
    const { className, ...rest } = props;

    // TODO: Convert to a button element: https://vistaprint.atlassian.net/browse/DT-12985
    return (
        <div
            ref={ref}
            className={classNames("dcl-add-shapes__icon", styles.dclAddShapes__icon, className)}
            {...rest}
            role="button"
        />
    );
});

SvgButton.displayName = "SvgButton";
