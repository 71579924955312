// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {
    iconText: string;
};

export function UnderlineIcon(props: Props) {
    const { iconText, ...rest } = props;
    return (
        <svg viewBox="0 0 32 32" fill="none" {...rest}>
            <text
                x="50%"
                y="51%"
                fontSize="23"
                fontFamily="arial"
                fontWeight="200"
                fill="currentColor"
                textAnchor="middle"
                dominantBaseline="central"
            >
                {iconText}
            </text>
            <path fill="currentColor" d="M 9 29 h 15 V 27 H 8 z" />
        </svg>
    );
}
UnderlineIcon.displayName = "UnderlineIcon";
