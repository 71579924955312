import React from "react";

export function RestoreProportionsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect x="2" y="2" width="8" height="8" fill="none" stroke="currentColor" strokeWidth="2" />
            <rect x="5" y="5" width="10" height="8" fill="none" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}
RestoreProportionsIcon.displayName = "RestoreProportionsIcon";
