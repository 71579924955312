import React from "react";

export function ShuffleIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 26 26" fill="none" {...props}>
            <circle cx="13" cy="13" r="13" fill="black" />
            <path
                d="M12.6807 10.3752C13.5082 8.87017 15.0895 7.93509 16.807 7.93509H17.4958V6.75606C17.4958 6.39235 17.9235 6.19731 18.1981 6.43581L20.5205 8.45303C20.7152 8.62214 20.7152 8.92441 20.5205 9.09352L18.1981 11.1107C17.9235 11.3492 17.4958 11.1542 17.4958 10.7905V9.43509H16.807C15.6366 9.43509 14.559 10.0723 13.9951 11.0979L11.5883 15.4748C10.7608 16.9798 9.17948 17.9148 7.46197 17.9148H5.73184C5.51319 17.9148 5.33594 17.7376 5.33594 17.5189V16.8107C5.33594 16.5921 5.51319 16.4148 5.73184 16.4148H7.46197C8.63239 16.4148 9.70998 15.7776 10.2739 14.752L12.6807 10.3752Z"
                fill="white"
            />
            <path
                d="M7.46197 8.08213C9.04789 8.08213 10.5177 8.87941 11.3851 10.1865L10.5385 11.7261L10.2739 11.2449C9.70998 10.2193 8.63239 9.58213 7.46197 9.58213H5.73184C5.51319 9.58213 5.33594 9.40488 5.33594 9.18623V8.47803C5.33594 8.25938 5.51319 8.08213 5.73184 8.08213H7.46197Z"
                fill="white"
            />
            <path
                d="M16.807 18.0619C15.1668 18.0619 13.6508 17.2091 12.7969 15.8216L13.6496 14.2709L13.9951 14.8991C14.559 15.9247 15.6366 16.5619 16.807 16.5619H17.4958V15.2065C17.4958 14.8428 17.9235 14.6478 18.1981 14.8863L20.5205 16.9035C20.7152 17.0726 20.7152 17.3749 20.5205 17.544L18.1981 19.5612C17.9235 19.7997 17.4958 19.6047 17.4958 19.2409V18.0619H16.807Z"
                fill="white"
            />
        </svg>
    );
}
ShuffleIcon.displayName = "ShuffleIcon";
