import React from "react";

export const FloppyDiskIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M2 3.47891C2 3.14754 2.26863 2.87891 2.6 2.87891H11.3747C11.5205 2.87891 11.6613 2.93202 11.7709 3.02831L13.7962 4.80908C13.9257 4.92299 14 5.08716 14 5.25967V14.2789C14 14.6103 13.7314 14.8789 13.4 14.8789H2.6C2.26863 14.8789 2 14.6103 2 14.2789V3.47891Z"
                fill="none"
            />
            <path
                d="M5.19006 2.87891V5.82326H10.8077V2.87891M13.7962 4.80908L11.7709 3.02831C11.6613 2.93202 11.5205 2.87891 11.3747 2.87891H2.6C2.26863 2.87891 2 3.14754 2 3.47891V14.2789C2 14.6103 2.26863 14.8789 2.6 14.8789H13.4C13.7314 14.8789 14 14.6103 14 14.2789V5.25967C14 5.08716 13.9257 4.92299 13.7962 4.80908Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <ellipse cx={8.0024} cy={9.90865} rx={1.83834} ry={1.83834} fill="none" stroke="currentColor" />
        </svg>
    );
};
FloppyDiskIcon.displayName = "FloppyDiskIcon";
