import React from "react";

export function RowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path opacity="0.6" d="M3 3L13.5 3L13.5 8.6875L3 8.5L3 3Z" fill="#666666" />
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke="currentColor" />
            <path d="M8 2.5V13.5" stroke="currentColor" />
            <path d="M13.5 8L2.5 8" stroke="currentColor" />
        </svg>
    );
}
RowIcon.displayName = "ColumnIcon";
