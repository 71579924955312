import React from "react";

export function FoldLineIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" {...props}>
            <rect opacity="0.5" width="24" height="24" rx="12" fill="#DC2918" fillOpacity="0.1" />
            <rect
                x="19.75"
                y="11.75"
                width="0.5"
                height="15.5"
                rx="0.25"
                transform="rotate(90 19.75 11.75)"
                fill="white"
                stroke="#DC2918"
                strokeWidth="0.5"
                strokeDasharray="1 1"
            />
        </svg>
    );
}
FoldLineIcon.displayName = "FoldLineIcon";
