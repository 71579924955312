import React from "react";

export function ResetArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4634 2.76398C10.6951 3.0198 10.6756 3.41504 10.4198 3.64679L8.24049 5.6211L10.4198 7.59541C10.6756 7.82715 10.6951 8.2224 10.4634 8.47821C10.2316 8.73403 9.8364 8.75354 9.58059 8.52179L6.37867 5.6211L9.58059 2.72041C9.8364 2.48866 10.2316 2.50817 10.4634 2.76398Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.68428 5.62104C6.68428 5.27586 6.9641 4.99604 7.30928 4.99604H9.9999C11.1797 4.99604 12.3325 5.34034 13.3097 6.00143C14.2948 6.65213 15.0588 7.5847 15.5087 8.67285C15.9594 9.7628 16.0744 10.9667 15.844 12.1186C15.6136 13.2706 15.0482 14.328 14.212 15.1648C13.3816 16.0051 12.3128 16.5696 11.1631 16.7996C10.0027 17.0296 8.80747 16.915 7.71735 16.4643L7.71262 16.4623C6.62776 16.0034 5.70659 15.2505 5.04721 14.2672C4.38528 13.2895 4.04053 12.136 4.04053 10.9554C4.04053 10.6102 4.32035 10.3304 4.66553 10.3304C5.01071 10.3304 5.29053 10.6102 5.29053 10.9554C5.29053 11.893 5.56422 12.8017 6.08298 13.5674L6.08471 13.57C6.60605 14.3479 7.33369 14.9442 8.19733 15.3101C9.0564 15.6648 9.99862 15.7559 10.9185 15.5737C11.8305 15.3912 12.6734 14.9438 13.3239 14.2851L13.3267 14.2823C13.9897 13.6193 14.4363 12.7834 14.6183 11.8735C14.8004 10.9628 14.7091 10.0105 14.3535 9.15047C13.9977 8.28981 13.3941 7.55448 12.6183 7.04277L12.6118 7.03852C11.8461 6.51977 10.9375 6.24604 9.9999 6.24604H7.30928C6.9641 6.24604 6.68428 5.96621 6.68428 5.62104Z"
            />
        </svg>
    );
}
ResetArrowIcon.displayName = "ResetArrowIcon";
