import React from "react";

export function ItemAlignmentCenterHorizontallyIcon() {
    return (
        <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m8.123 0.28516c-0.34518 0.001077-0.62413 0.28177-0.62305 0.62695l0.0039062 1.0879h-1.7363c-0.40469 0-0.75 0.34531-0.75 0.75v3.5c0 0.40469 0.34531 0.75 0.75 0.75h1.7539l0.0058594 2h-3.998c-0.40469 0-0.75 0.34531-0.75 0.75v3.5c0 0.40469 0.34531 0.75 0.75 0.75h4.0156l0.0019531 1.0898c0.00108 0.34518 0.28177 0.62413 0.62695 0.62305 0.34518-0.0011 0.62413-0.28177 0.62305-0.62695l-0.0019531-1.0859h3.9727c0.40469 0 0.75-0.34531 0.75-0.75v-3.5c0-0.40469-0.34531-0.75-0.75-0.75h-3.9902l-0.0058594-2h1.7578c0.40469 0 0.75-0.34531 0.75-0.75v-3.5c0-0.40469-0.34531-0.75-0.75-0.75h-1.7754l-0.0039062-1.0898c-0.00108-0.34518-0.28177-0.62608-0.62695-0.625zm-1.8555 2.9648h3.7617v2.5h-3.7617v-2.5zm-2.2383 7h8.2383v2.5h-8.2383v-2.5z"
                fill="currentColor"
            />
        </svg>
    );
}
ItemAlignmentCenterHorizontallyIcon.displayName = "ItemAlignmentCenterHorizontallyIcon";
