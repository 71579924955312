import React from "react";

export function JpgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" viewBox="0 0 24 24" {...props}>
            <path
                d="m3 3c0-1.65685 1.34315-3 3-3h10.7574c.7956 0 1.5587.316071 2.1213.87868l4.2426 4.24264c.5626.56261.8787 1.32567.8787 2.12132v13.75736c0 1.6569-1.3431 3-3 3h-15c-1.65685 0-3-1.3431-3-3z"
                fill="#5bb1f1"
            />
            <path
                d="m24 7.49993h-4.5001c-1.6568 0-3-1.34315-3-3v-4.49993h.2575c.7921 0 1.5519.313258 2.1137.871169l4.2576 4.257601c.558.56188.8713 1.32173.8713 2.11387z"
                fill="#0073c7"
            />
            <path
                d="m0 11.4c0-1.3255 1.07452-2.4 2.4-2.4h16.2c1.3255 0 2.4 1.0745 2.4 2.4v5.7c0 1.3255-1.0745 2.4-2.4 2.4h-16.2c-1.32548 0-2.4-1.0745-2.4-2.4z"
                fill="#005ab8"
            />
            <g fill="#fff">
                <path d="m15.3326 10.5235c-.9821.2213-1.8326 1.0537-1.8326 2.377v2.8495c0 .6978.2765 1.2728.7276 1.6637.4358.3777.9927.5488 1.5224.5488.5298 0 1.0866-.1711 1.5225-.5488.451-.3909.7275-.9659.7275-1.6636v-2.2501h-2.25v1.5h.75v.75c0 .2773-.0985.4335-.2099.5302-.1267.1097-.3198.1823-.5401.1823-.2202 0-.4134-.0726-.54-.1824-.1115-.0966-.21-.2528-.21-.5301v-2.8495c0-.5703.3128-.835.6624-.9138.4061-.0915.7747.076.9224.3597l1.3304-.6927c-.5257-1.0098-1.6571-1.3389-2.5826-1.1302z" />
                <path d="m4.5 15.75v-5.25h1.5v5.25c0 .7372-.25027 1.3578-.68842 1.796-.4311.4311-1.00106.6415-1.56158.6415s-1.13048-.2104-1.56158-.6415c-.43815-.4382-.68842-1.0588-.68842-1.796h1.5c0 .3878.12473.6109.24908.7353.1314.1314.31144.2022.50092.2022s.36952-.0708.50092-.2022c.12435-.1244.24908-.3475.24908-.7353z" />
                <path
                    clipRule="evenodd"
                    d="m9.525 10.5h-2.025v7.5h1.5v-3h.525c.7605 0 1.401-.2412 1.8526-.6819.4477-.4368.6551-1.0177.6409-1.5868-.0292-1.1678-.9727-2.2313-2.4935-2.2313zm0 3h-.525v-1.5h.525c.7292 0 .9857.4365.994.7687.0045.1809-.0599.35-.1888.4757-.1249.1218-.36572.2556-.8052.2556z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
}
JpgIcon.displayName = "JpgIcon";
