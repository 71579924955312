// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {
    iconText: string;
};

export function StrikethroughIcon(props: Props) {
    const { iconText, ...rest } = props;
    return (
        <svg viewBox="0 0 32 32" fill="none" {...rest}>
            <defs>
                <clipPath id="cut-off-center">
                    <rect width="32" height="15" />
                    <rect y="18" width="32" height="14" />
                </clipPath>
            </defs>
            <text
                x="50%"
                y="54%"
                fontSize="26"
                fontFamily="arial"
                fontWeight="400"
                fill="currentColor"
                textAnchor="middle"
                dominantBaseline="central"
                clipPath="url(#cut-off-center)"
            >
                {iconText}
            </text>
            <rect fill="currentColor" y="16" x="6" width="20" height="1" />
        </svg>
    );
}
StrikethroughIcon.displayName = "StrikethroughIcon";
