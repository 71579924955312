import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./SidebarItem.module.scss";

export interface SidebarItemProps {
    children: ReactNode;
    className?: string;
}

export const SidebarItem = ({ children, className }: SidebarItemProps) => {
    return <div className={classnames("sidebar-item", styles.sidebarItem, className)}>{children}</div>;
};
