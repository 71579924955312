import React, { Ref, forwardRef } from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /** Whether or not the button is selected (eg toggled on)
     * @default false
     */
    isSelected?: boolean;
    /** Whether or not the button is disabled
     * @default false
     */
    isDisabled?: boolean;
    /** Whether or not the button is loading
     * @default false
     */
    isLoading?: boolean;
    /** Title of the button, also used to set info for accessability
     */
    title?: string;
    /** Click handler
     */
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    /** For overriding styles
     */
    className?: string;
    // Forcing a particular order seemed like a loss of flexibility that would be undesirable.
    /** Child elements
     * Put in the icon / text is expected here.
     */
    children?: React.ReactNode;
}

/**
 * This is a Basic Button
 */
export const Button = forwardRef(
    (
        {
            children,
            className,
            isSelected = false,
            isDisabled = false,
            isLoading = false,
            title,
            onClick,
            ...props
        }: Props,
        ref: Ref<HTMLButtonElement>
    ) => {
        return (
            <button
                className={classnames(
                    "easel-button",
                    "easel-button-theme-override",
                    {
                        "easel-button-selected": isSelected && !isDisabled
                    },
                    styles.easelButton,
                    styles.easelButtonThemeOverride,
                    {
                        [styles.easelButtonSelected]: isSelected && !isDisabled
                    },
                    className
                )}
                title={title}
                aria-label={title}
                type="button"
                disabled={isDisabled || isLoading}
                onClick={onClick}
                ref={ref}
                {...props}
            >
                {children}
            </button>
        );
    }
);
Button.displayName = "Button";
