// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function DuplicateIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} fill="white" />
            <rect
                width={9}
                height={9}
                rx={1}
                transform="matrix(-1 0 0 1 11 5)"
                fill="white"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <rect
                width={9}
                height={9}
                rx={1}
                transform="matrix(-1 0 0 1 14 2)"
                fill="white"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path d="M9.5 5L9.5 8" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M8 6.5L11 6.5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
DuplicateIcon.displayName = "DuplicateIcon";
