import React from "react";

export function ChangeTrimToRoundedIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8632 0.136719H9.13681C4.16625 0.136719 0.13681 4.16616 0.13681 9.13672V13.8631L2.13681 11.8631V9.13672C2.13681 5.27073 5.27082 2.13672 9.13681 2.13672H11.8632L13.8632 0.136719Z"
                fill="currentColor"
            />
        </svg>
    );
}
ChangeTrimToRoundedIcon.displayName = "ChangeTrimToRoundedIcon";
