import React, { Ref } from "react";
import { FlexBox, FlexBoxProps } from "@vp/swan";
import classNames from "classnames";
import styles from "./StickyActionBar.module.scss";

type StickyActionBarProps = FlexBoxProps & {
    className?: string;
};
export const StickyActionBar = React.forwardRef(
    ({ children, className, ...rest }: StickyActionBarProps, ref: Ref<HTMLDivElement>) => {
        return (
            <FlexBox ref={ref} className={classNames(styles.stickyActionBar, className)} {...rest}>
                {children}
            </FlexBox>
        );
    }
);

StickyActionBar.displayName = "StickyActionBar";
