import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function AddTableRowBelowIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M2 13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V10C14 9.44772 13.5523 9 13 9H3C2.44772 9 2 9.44772 2 10V13Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path d="M8 2L8 6M6 4L10 4" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
AddTableRowBelowIcon.displayName = "AddTableRowBelowIcon";
