// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function TextAlignIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path d="M5 12H11" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M3 8H13" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M5 4H11" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
TextAlignIcon.displayName = "TextAlignIcon";
