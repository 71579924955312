import React from "react";

export const HelpIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="20" cy="20" r="20" fill="#3B5FF3" />
            <path
                d="M 19.8076 31.9912 C 21.4749 31.9912 23.0407 31.6738 24.5049 31.0391 C 25.9691 30.4043 27.2598 29.5283 28.377 28.4111 C 29.5026 27.2939 30.3828
                   26.0033 31.0176 24.5391 C 31.6523 23.0664 31.9697 21.4964 31.9697 19.8291 C 31.9697 18.1702 31.6481 16.6087 31.0049 15.1445 C 30.3701 13.6719
                   29.4899 12.377 28.3643 11.2598 C 27.2471 10.1341 25.9564 9.2539 24.4922 8.6191 C 23.028 7.9844 21.4622 7.667 19.7949 7.667 C 18.1361 7.667 16.5745
                   7.9844 15.1104 8.6191 C 13.6462 9.2539 12.3512 10.1341 11.2256 11.2598 C 10.1084 12.377 9.2324 13.6719 8.5977 15.1445 C 7.9629 16.6087 7.6455 18.1702
                   7.6455 19.8291 C 7.6455 21.4964 7.9629 23.0664 8.5977 24.5391 C 9.2409 26.0033 10.1211 27.2939 11.2383 28.4111 C 12.3555 29.5283 13.6462 30.4043
                   15.1104 31.0391 C 16.5745 31.6738 18.1403 31.9912 19.8076 31.9912 Z M 19.8076 30.1504 C 18.3773 30.1504 17.0358 29.8838 15.7832 29.3506 C 14.5391
                   28.8174 13.4473 28.0811 12.5078 27.1416 C 11.5684 26.1937 10.832 25.0977 10.2988 23.8535 C 9.7656 22.6009 9.499 21.2594 9.499 19.8291 C 9.499 18.4072
                   9.7656 17.0742 10.2988 15.8301 C 10.832 14.5775 11.5684 13.4814 12.5078 12.542 C 13.4473 11.5941 14.5391 10.8535 15.7832 10.3203 C 17.0273 9.7871
                   18.3646 9.5205 19.7949 9.5205 C 21.2337 9.5205 22.5752 9.7871 23.8193 10.3203 C 25.0635 10.8535 26.1553 11.5941 27.0947 12.542 C 28.0426 13.4814
                   28.7832 14.5775 29.3164 15.8301 C 29.8496 17.0742 30.1162 18.4072 30.1162 19.8291 C 30.1247 21.2594 29.8623 22.6009 29.3291 23.8535 C 28.7959
                   25.0977 28.0553 26.1937 27.1074 27.1416 C 26.168 28.0811 25.0719 28.8174 23.8193 29.3506 C 22.5752 29.8838 21.238 30.1504 19.8076 30.1504 Z M 19.8203
                   25.8086 C 20.6328 25.8086 21.348 25.6605 21.9658 25.3643 C 22.5921 25.0596 23.0788 24.7168 23.4258 24.3359 C 23.7812 23.9466 23.959 23.625 23.959
                   23.3711 C 23.959 23.2357 23.904 23.1426 23.7939 23.0918 C 23.6924 23.041 23.5824 23.0452 23.4639 23.1045 C 23.0492 23.3584 22.554 23.5996 21.9785
                   23.8281 C 21.403 24.0482 20.6836 24.1582 19.8203 24.1582 C 18.9401 24.1582 18.2165 24.0439 17.6494 23.8154 C 17.0824 23.5869 16.5872 23.3499 16.1641
                   23.1045 C 16.0456 23.0452 15.9313 23.041 15.8213 23.0918 C 15.7113 23.1426 15.6563 23.2357 15.6563 23.3711 C 15.6563 23.625 15.8298 23.9466 16.1768
                   24.3359 C 16.5322 24.7168 17.0189 25.0596 17.6367 25.3643 C 18.263 25.6605 18.9909 25.8086 19.8203 25.8086 Z M 16.2402 18.75 C 16.6126 18.75 16.9385
                   18.5977 17.2178 18.293 C 17.4971 17.9883 17.6367 17.6032 17.6367 17.1377 C 17.6367 16.6637 17.4971 16.2786 17.2178 15.9824 C 16.9469 15.6777 16.6211
                   15.5254 16.2402 15.5254 C 15.8678 15.5254 15.5462 15.6777 15.2754 15.9824 C 15.0046 16.2786 14.8691 16.6637 14.8691 17.1377 C 14.8691 17.6032 15.0046
                   17.9883 15.2754 18.293 C 15.5547 18.5977 15.8763 18.75 16.2402 18.75 Z M 23.375 18.75 C 23.7474 18.75 24.0732 18.5977 24.3525 18.293 C 24.6318 17.9883
                   24.7715 17.6032 24.7715 17.1377 C 24.7715 16.6637 24.6318 16.2786 24.3525 15.9824 C 24.0817 15.6777 23.7559 15.5254 23.375 15.5254 C 23.0026 15.5254
                   22.681 15.6777 22.4102 15.9824 C 22.1393 16.2786 22.0039 16.6637 22.0039 17.1377 C 22.0039 17.6032 22.1393 17.9883 22.4102 18.293 C 22.6895 18.5977
                   23.0111 18.75 23.375 18.75 Z"
                fill="white"
            />
        </svg>
    );
};
HelpIcon.displayName = "HelpIcon";
