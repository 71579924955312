import React from "react";

export function DragIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 19" fill="none" {...props}>
            <path d="M15.4737 7.47862L18 10.0049L15.4737 12.5312" stroke="currentColor" strokeLinecap="round" />
            <path d="M4.52632 12.5292L2 10.0029L4.52632 7.47656" stroke="currentColor" strokeLinecap="round" />
            <path d="M18 10H2" stroke="currentColor" />
            <path d="M12.5272 15.4737L10.0009 18L7.47461 15.4737" stroke="currentColor" strokeLinecap="round" />
            <path d="M7.47471 4.52632L10.001 2L12.5273 4.52632" stroke="currentColor" strokeLinecap="round" />
            <path d="M10.002 18L10.002 2" stroke="currentColor" />
        </svg>
    );
}
DragIcon.displayName = "DragIcon";
