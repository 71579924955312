import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function BucketColorIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" height="16px" width="16px" fill="none" {...props}>
            <path
                d="M15 13.6667C15 14.403 14.3284 15 13.5 15C12.6716 15 12 14.403 12 13.6667C12 12.9303 13.5 11 13.5 11C13.5 11 15 12.9303 15 13.6667Z"
                fill="currentColor"
                className="color-drop"
            />
            <path
                d="M5.99995 1.5L7.49995 3L12.2929 7.65737C12.693 8.04616 12.6976 8.68716 12.3031 9.08166L8.07078 13.3141C7.68026 13.7046 7.04709 13.7046 6.65657 13.3141L2.61516 9.27267C2.22464 8.88215 2.22464 8.24898 2.61516 7.85846L7.32496 3.14866"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                className="rect-color"
            />
            <path className="triangle" d="M13 8H3.00751H2L7.5 13.5L13 8Z" fill="currentColor" />
        </svg>
    );
}
BucketColorIcon.displayName = "BucketColorIcon";
