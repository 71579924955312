import React from "react";

export function RulersIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" {...props}>
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#F4F4F4" />
            <rect opacity="0.1" width="24" height="24" rx="12" fill="#A0A4A6" />
            <line x1="12" y1="4" x2="12" y2="20" stroke="black" strokeOpacity="0.9" />
            <line x1="9" y1="19.5" x2="15" y2="19.5" stroke="black" strokeOpacity="0.9" />
            <line x1="9" y1="4.5" x2="15" y2="4.5" stroke="black" strokeOpacity="0.9" />
        </svg>
    );
}
RulersIcon.displayName = "RulersIcon";
