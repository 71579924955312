import React from "react";

export function FullCurveUpIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M13.5318 10.4256C13.6288 10.6841 13.917 10.8151 14.1756 10.7182C14.4341 10.6212 14.5651 10.333 14.4682 10.0744L13.5318 10.4256ZM1.53184 10.0744C1.43488 10.333 1.56588 10.6212 1.82444 10.7182C2.083 10.8151 2.3712 10.6841 2.46816 10.4256L1.53184 10.0744ZM14.4682 10.0744C12.2269 4.09785 3.77306 4.09785 1.53184 10.0744L2.46816 10.4256C4.38495 5.31414 11.6151 5.31414 13.5318 10.4256L14.4682 10.0744Z"
                fill="currentColor"
            />
        </svg>
    );
}
FullCurveUpIcon.displayName = "FullCurveUpIcon";
