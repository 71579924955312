import React from "react";

export function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M3.2002 10.6993C3.2002 10.3679 3.46882 10.0993 3.8002 10.0993L7.40019 10.0993C7.73157 10.0993 8.00019 10.3679 8.00019 10.6993C8.00019 11.0307 7.73157 11.2993 7.40019 11.2993L3.8002 11.2993C3.46882 11.2993 3.2002 11.0307 3.2002 10.6993Z"
                fill="currentColor"
            />
            <path
                d="M8.00021 5.09971C8.00021 4.76834 8.26884 4.49971 8.60021 4.49971L12.2002 4.49971C12.5316 4.49971 12.8002 4.76834 12.8002 5.09971C12.8002 5.43108 12.5316 5.69971 12.2002 5.69971L8.60021 5.69971C8.26884 5.69971 8.00021 5.43108 8.00021 5.09971Z"
                fill="currentColor"
            />
            <path
                d="M5.8005 14.5005C5.46913 14.5005 5.2005 14.2319 5.2005 13.9005L5.2005 2.30049C5.2005 1.96912 5.46913 1.70049 5.8005 1.70049C6.13187 1.70049 6.4005 1.96912 6.4005 2.30049L6.4005 13.9005C6.4005 14.2319 6.13187 14.5005 5.8005 14.5005Z"
                fill="currentColor"
            />
            <path
                d="M10.6003 14.5005C10.2689 14.5005 10.0003 14.2319 10.0003 13.9005L10.0003 2.30049C10.0003 1.96912 10.2689 1.70049 10.6003 1.70049C10.9317 1.70049 11.2003 1.96912 11.2003 2.30049L11.2003 13.9005C11.2003 14.2319 10.9317 14.5005 10.6003 14.5005Z"
                fill="currentColor"
            />
        </svg>
    );
}
SettingsIcon.displayName = "SettingsIcon";
