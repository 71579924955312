import React, { forwardRef, Ref, ReactNode, HTMLProps } from "react";
import classnames from "classnames";

interface Props extends HTMLProps<HTMLDivElement> {
    isSelected: boolean;
    children?: ReactNode | ReactNode[];
    className?: string;
    ref?: Ref<HTMLDivElement>;
}

export const ContentPanel = forwardRef(({ children, className, isSelected }: Props, ref: Ref<HTMLDivElement>) => {
    return (
        <div ref={ref} className={classnames(className, isSelected ? "selected" : "")}>
            {children}
        </div>
    );
});

ContentPanel.displayName = "ContentPanel";
