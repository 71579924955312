import React, { DetailedHTMLProps, SVGAttributes } from "react";

export function TablesIcon(props: DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 24" width="20" height="20" aria-hidden="true">
            <svg viewBox="0 0 22 22">
                <path
                    fill="currentColor"
                    d="M2.095 9.952h7.857V2.095H2.095v7.857zm0 2.096v7.857h7.857v-7.857H2.095zm17.81-2.096V2.095h-7.857v7.857h7.857zm0 2.096h-7.857v7.857h7.857v-7.857zM22 0v22H0V0h22z"
                />
            </svg>
        </svg>
    );
}

TablesIcon.displayName = "TablesIcon";
