// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function CurvedTextIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path d="M14 2V2C7.37258 2 2 7.37258 2 14V14" stroke="black" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
CurvedTextIcon.displayName = "CurvedTextIcon";
