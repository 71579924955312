// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {
    iconText: string;
};

export function BoldIcon(props: Props) {
    const { iconText, ...rest } = props;
    return (
        <svg viewBox="0 0 32 32" fill="none" {...rest}>
            <text
                x="50%"
                y="50%"
                fontSize="26"
                fontFamily="arial black"
                fill="currentColor"
                textAnchor="middle"
                dominantBaseline="central"
            >
                {iconText}
            </text>
        </svg>
    );
}
BoldIcon.displayName = "BoldIcon";
