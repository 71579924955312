import React, { ReactNode } from "react";
import styles from "./LeftSidebar.module.scss";

type SidebarButtonIconProps = {
    children: ReactNode | ReactNode[];
};

export const SidebarButtonIcon = (props: SidebarButtonIconProps) => {
    const { children } = props;
    return <div className={styles.sidebarButtonIcon}>{children}</div>;
};

SidebarButtonIcon.displayName = "SidebarButtonIcon";
