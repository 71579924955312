import { RefObject, useEffect } from "react";

/**
 * This effect is responsible for capturing clicks outside of an element (by ref) and then invoking a callback
 * @param wrapperReference Reference to an elment to detect the click outside of
 * @param callback What to do if a click outside the reference happens.
 */
export function useClickOutside(
    wrapperReference: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    callback: () => void,
    dependencies: any[] = []
) {
    const refs = Array.isArray(wrapperReference) ? wrapperReference : [wrapperReference];
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (!refs.some(ref => ref.current && ref.current.contains(event.target as Node))) {
                callback();
            }
        }

        document.addEventListener("click", handleClickOutside, true);
        return () => document.removeEventListener("click", handleClickOutside, true);
    }, [...refs.map(ref => ref.current), ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps --  this dependency array is correct given the use case
}
