import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {
    alt?: string;
};

export function PreviewIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M8 12.2853C5.79658 12.2853 3.29506 10.149 1.96009 8.82941C1.39282 8.26869 1.41226 7.37521 2.00486 6.84132C3.3504 5.62906 5.82097 3.71387 8 3.71387C10.179 3.71387 12.6496 5.62906 13.9951 6.84132C14.5877 7.37522 14.6072 8.26869 14.0399 8.82941C12.7049 10.149 10.2034 12.2853 8 12.2853Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
            <path
                d="M10.3002 7.99972C10.3002 9.31111 9.26098 10.3569 8.0002 10.3569C6.73942 10.3569 5.7002 9.31111 5.7002 7.99972C5.7002 6.68834 6.73942 5.64258 8.0002 5.64258C9.26098 5.64258 10.3002 6.68834 10.3002 7.99972Z"
                stroke="currentColor"
            />
        </svg>
    );
}
PreviewIcon.displayName = "PreviewIcon";
