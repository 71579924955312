// This code has been generated via svgr

import React from "react";

export function DoubleDownChevronIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M4.25 4.375L8 7.75C8 7.75 10.3214 5.66071 11.75 4.375"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.25 9.375L8 12.75C8 12.75 10.3214 10.6607 11.75 9.375"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
DoubleDownChevronIcon.displayName = "DoubleDownChevronIcon";
