import React from "react";

export function ItemAlignmentLeftIcon() {
    return (
        <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m1.123 0.19336a0.625 0.625 0 0 0-0.62305 0.62695l0.046875 14.178a0.625 0.625 0 0 0 0.62695 0.62305 0.625 0.625 0 0 0 0.62305-0.62695l-0.046875-14.178a0.625 0.625 0 0 0-0.62695-0.62305zm3.127 1.6035c-0.40469 0-0.75 0.34531-0.75 0.75v3.75c0 0.40469 0.34531 0.75 0.75 0.75h4.5c0.40469 0 0.75-0.34531 0.75-0.75v-3.75c0-0.40469-0.34531-0.75-0.75-0.75h-4.5zm0.5 1.25h3.5v2.75h-3.5v-2.75zm-0.5 5.75c-0.40469 0-0.75 0.34531-0.75 0.75v3.75c0 0.40469 0.34531 0.75 0.75 0.75h9.5c0.40469 0 0.75-0.34531 0.75-0.75v-3.75c0-0.40469-0.34531-0.75-0.75-0.75h-9.5zm0.5 1.25h8.5v2.75h-8.5v-2.75z"
                fill="currentColor"
            />
        </svg>
    );
}
ItemAlignmentLeftIcon.displayName = "ItemAlignmentLeftIcon";
