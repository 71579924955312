import React from "react";

export function ChangeTrimToStandardIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0004 0.00310889C11.982 0.0010548 11.9633 0 11.9444 0H0.5C0.223858 0 0 0.223858 0 0.5V11.9444C0 11.9633 0.0010548 11.982 0.00310889 12.0004L2 10.0035V2H10.0035L12.0004 0.00310889Z"
                fill="currentColor"
            />
        </svg>
    );
}
ChangeTrimToStandardIcon.displayName = "ChangeTrimToStandardIcon";
