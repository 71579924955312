import React from "react";

export function LockedItemIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 17 16" fill="none" {...props}>
            <path
                d="M6.01074 14.3057H11.9717C12.4456 14.3057 12.7988 14.1826 13.0312 13.9365C13.2682 13.695 13.3867 13.319 13.3867 12.8086V8.2832C13.3867 7.77279 13.2682 7.39681 13.0312 7.15527C12.7988 6.91374 12.4456 6.79297 11.9717 6.79297H6.01074C5.53678 6.79297 5.18132 6.91374 4.94434 7.15527C4.71191 7.39681 4.5957 7.77279 4.5957 8.2832V12.8086C4.5957 13.319 4.71191 13.695 4.94434 13.9365C5.18132 14.1826 5.53678 14.3057 6.01074 14.3057ZM6.03809 13.4238C5.86947 13.4238 5.74414 13.3805 5.66211 13.2939C5.58008 13.2119 5.53906 13.0706 5.53906 12.8701V8.22168C5.53906 8.0166 5.58008 7.87533 5.66211 7.79785C5.74414 7.71582 5.86947 7.6748 6.03809 7.6748H11.9512C12.1152 7.6748 12.2383 7.71582 12.3203 7.79785C12.4023 7.87533 12.4434 8.0166 12.4434 8.22168V12.8701C12.4434 13.0706 12.4023 13.2119 12.3203 13.2939C12.2383 13.3805 12.1152 13.4238 11.9512 13.4238H6.03809ZM5.72363 7.25781H6.66699V5.14551C6.66699 4.59863 6.77181 4.13607 6.98145 3.75781C7.19564 3.375 7.47819 3.08561 7.8291 2.88965C8.18001 2.68913 8.56738 2.58887 8.99121 2.58887C9.41504 2.58887 9.80241 2.68913 10.1533 2.88965C10.5042 3.08561 10.7845 3.375 10.9941 3.75781C11.2038 4.13607 11.3086 4.59863 11.3086 5.14551V7.25781H12.252V5.26172C12.252 4.67383 12.1631 4.15658 11.9854 3.70996C11.8122 3.26335 11.5729 2.89193 11.2676 2.5957C10.9622 2.29492 10.6136 2.06934 10.2217 1.91895C9.82975 1.76855 9.4196 1.69336 8.99121 1.69336C8.56283 1.69336 8.15267 1.76855 7.76074 1.91895C7.36882 2.06934 7.02018 2.29492 6.71484 2.5957C6.40951 2.89193 6.16797 3.26335 5.99023 3.70996C5.8125 4.15658 5.72363 4.67383 5.72363 5.26172V7.25781Z"
                fill="currentColor"
            />
        </svg>
    );
}

LockedItemIcon.displayName = "LockedItemIcon";
