import React from "react";

export function CounterIcon(props: React.SVGProps<SVGSVGElement>) {
    const { ...rest } = props;
    return (
        <svg viewBox="0 0 16 21" fill="none" {...rest}>
            <path
                d="M3.5293 1.71094H12.083C12.0366 1.0708 11.6655 0.718262 10.979 0.718262H4.6333C3.9375 0.718262 3.57568 1.0708 3.5293 1.71094ZM2.0542 4.03027H13.5488C13.4468 3.35303 13.1128 2.9541 12.3706 2.9541H3.2417C2.49023 2.9541 2.15625 3.35303 2.0542 4.03027ZM2.88916 20.6367H12.9365C14.4858 20.6367 15.3765 19.7461 15.3765 18.0205V8.12158C15.3765 6.38672 14.4766 5.49609 12.7231 5.49609H2.88916C1.12646 5.49609 0.23584 6.37744 0.23584 8.12158V18.0205C0.23584 19.7554 1.12646 20.6367 2.88916 20.6367ZM2.90771 19.4307C1.96143 19.4307 1.45117 18.9204 1.45117 17.9556V8.18652C1.45117 7.2124 1.96143 6.71143 2.90771 6.71143H12.7046C13.6323 6.71143 14.1611 7.2124 14.1611 8.18652V17.9556C14.1611 18.9204 13.6323 19.4307 12.9087 19.4307H2.90771Z"
                fill="currentColor"
            />
        </svg>
    );
}
CounterIcon.displayName = "CounterIcon";
