// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function LeftAlignIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M10 10H2V11.3333H10V10ZM10 4.66667H2V6H10V4.66667ZM2 8.66667H14V7.33333H2V8.66667ZM2 14H14V12.6667H2V14ZM2 2V3.33333H14V2H2Z"
                fill="currentColor"
            />
        </svg>
    );
}

LeftAlignIcon.displayName = "LeftAlignIcon";
