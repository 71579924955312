import React from "react";
import { Button, ButtonProps } from "@vp/swan";
import classNames from "classnames";
import styles from "./SmallScreenAddContentToolbarButton.module.scss";

interface SmallScreenAddContentToolbarButtonProps extends ButtonProps {
    dataTestId?: string;
}

export const SmallScreenAddContentToolbarButton = (props: SmallScreenAddContentToolbarButtonProps) => {
    const { children, disabled, onClick, dataTestId, ...rest } = props;
    return (
        <Button
            skin="unstyled"
            compactMode
            {...rest}
            className={classNames(styles.smallscreenAddcontentToolbarButton, {
                [styles.smallscreenAddcontentToolbarButtonDisabled]: disabled
            })}
            onClick={onClick}
            disabled={disabled}
            data-testid={dataTestId}
        >
            {children}
        </Button>
    );
};

SmallScreenAddContentToolbarButton.displayName = "SmallScreenAddContentToolbarButton";
