import React from "react";

export function TextIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="translate(3,3)">
                <path
                    d="M1 3.5V2.87667C0.999566 2.3791 1.19333 1.90176 1.53861 1.54976C1.8839 1.19777 2.35239 1 2.84091 1H16.3409C16.8294 1 17.2979 1.19777 17.6432 1.54976C17.9885 1.90176 18.1823 2.3791 18.1818 2.87667V3.50167"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.59073 1V19.75"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.90918 19.75H13.2728"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
TextIcon.displayName = "TextIcon";
