import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function GraphicsIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M 6 10.8 H 2.4 C 1.7372 10.8 1.2 10.2628 1.2 9.6 V 2.4 C 1.2 1.7372 1.7372 1.2 2.4 1.2 H 9.6 C 10.2628 1.2 10.8 1.7372 10.8 2.4 V 6 M 6 10.8 A 4.8 4.8 90 1 0 15.6 10.8 A 4.8 4.8 90 1 0 6 10.8"
                stroke="currentColor"
            />
        </svg>
    );
}
GraphicsIcon.displayName = "GraphicsIcon";
