// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function RightAlignIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M6 10H14V11.3333H6V10ZM6 4.66667H14V6H6V4.66667ZM14 8.66667H2V7.33333H14V8.66667ZM14 14H2V12.6667H14V14ZM14 2V3.33333H2V2H14Z"
                fill="currentColor"
            />
        </svg>
    );
}

RightAlignIcon.displayName = "RightAlignIcon";
