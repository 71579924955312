import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function QRCodeIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M2 7.33333H7.33333V2H2V7.33333ZM3.33333 3.33333H6V6H3.33333V3.33333Z" fill="currentColor" />
            <path
                d="M2 13.9993H7.33333V8.66602H2V13.9993ZM3.33333 9.99935H6V12.666H3.33333V9.99935Z"
                fill="currentColor"
            />
            <path d="M8.66699 2V7.33333H14.0003V2H8.66699ZM12.667 6H10.0003V3.33333H12.667V6Z" fill="currentColor" />
            <path d="M14.0003 12.666H12.667V13.9993H14.0003V12.666Z" fill="currentColor" />
            <path d="M10.0003 8.66602H8.66699V9.99935H10.0003V8.66602Z" fill="currentColor" />
            <path d="M11.3333 10H10V11.3333H11.3333V10Z" fill="currentColor" />
            <path d="M10.0003 11.334H8.66699V12.6673H10.0003V11.334Z" fill="currentColor" />
            <path d="M11.3333 12.666H10V13.9993H11.3333V12.666Z" fill="currentColor" />
            <path d="M12.6663 11.334H11.333V12.6673H12.6663V11.334Z" fill="currentColor" />
            <path d="M12.6663 8.66602H11.333V9.99935H12.6663V8.66602Z" fill="currentColor" />
            <path d="M14.0003 10H12.667V11.3333H14.0003V10Z" fill="currentColor" />
        </svg>
    );
}
QRCodeIcon.displayName = "QRCodeIcon";
