import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function UploadIcon(props: Props) {
    return (
        <svg viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M 2.743 16.999 C 6 17 12.6962 17.0028 17.135 17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M10 4.10938L10.0015 13.2122" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M6.82031 6.47656L10.0003 2.99831L13.1803 6.47656"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
UploadIcon.displayName = "UploadIcon";
