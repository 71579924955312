import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function TableDesignIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M2 8H8V14H3C2.44772 14 2 13.5523 2 13V8Z" stroke="currentColor" strokeWidth={1.5} />
            <path d="M14 8H8V14H13C13.5523 14 14 13.5523 14 13V8Z" stroke="currentColor" strokeWidth={1.5} />
            <path d="M2 8H8V2H3C2.44772 2 2 2.44772 2 3V8Z" stroke="currentColor" strokeWidth={1.5} />
            <path d="M14 8H8V2H13C13.5523 2 14 2.44772 14 3V8Z" stroke="currentColor" strokeWidth={1.5} />
        </svg>
    );
}
TableDesignIcon.displayName = "TableDesignIcon";
