import React from "react";

export function PatternIcon(props: React.SVGProps<SVGSVGElement>) {
    const { color: strokeColor } = props;

    return (
        <svg viewBox="0 0 12 12" fill="none" {...props}>
            <rect x="0.5" y="0.5" width="2.27279" height="2.27279" rx="1.13639" stroke={strokeColor} />
            <rect x="0.5" y="4.86328" width="2.27279" height="2.27279" rx="0.5" stroke={strokeColor} />
            <rect x="0.5" y="9.22754" width="2.27279" height="2.27279" rx="1.13639" stroke={strokeColor} />
            <rect x="4.86353" y="0.5" width="2.27279" height="2.27279" rx="0.5" stroke={strokeColor} />
            <rect x="4.86353" y="4.86328" width="2.27279" height="2.27279" rx="1.13639" stroke={strokeColor} />
            <rect x="4.86353" y="9.22754" width="2.27279" height="2.27279" rx="0.5" stroke={strokeColor} />
            <rect x="9.22729" y="0.5" width="2.27279" height="2.27279" rx="1.13639" stroke={strokeColor} />
            <rect x="9.22729" y="4.86328" width="2.27279" height="2.27279" rx="0.5" stroke={strokeColor} />
            <rect x="9.22729" y="9.22754" width="2.27279" height="2.27279" rx="1.13639" stroke={strokeColor} />
        </svg>
    );
}
PatternIcon.displayName = "PatternIcon";
