import React from "react";

export function UnlockedIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_6183_441)">
                <path
                    d="M2 6H10C10.5523 6 11 6.44772 11 7V13C11 13.5523 10.5523 14 10 14H2C1.44771 14 1 13.5523 1 13V7C1 6.44772 1.44771 6 2 6Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <path
                    d="M9 6V5C9 3.34315 10.3432 2 12 2C13.6568 2 15 3.34315 15 5V6"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_6183_441">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
UnlockedIcon.displayName = "UnlockedIcon";
