import React from "react";

export function StraightIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M2 8L14 8" stroke="currentColor" strokeLinecap="round" />
        </svg>
    );
}
StraightIcon.displayName = "StraightIcon";
