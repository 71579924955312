import React from "react";

type Props = React.SVGProps<SVGSVGElement>;
export function WindowsOptionIcon(props: Props) {
    return (
        <svg viewBox="0 0 12 12" fill="none" {...props}>
            <path
                d="M0.890625 11.083H3.53906C3.78906 11.083 4.01172 11.0186 4.20703 10.8896C4.40234 10.7607 4.55273 10.583 4.6582 10.3564L8.26758 2.28809C8.34961 2.1123 8.49414 2.02441 8.70117 2.02441H11.1094C11.2695 2.02441 11.4062 1.97168 11.5195 1.86621C11.6328 1.75684 11.6895 1.62598 11.6895 1.47363C11.6895 1.31738 11.6328 1.18652 11.5195 1.08105C11.4062 0.97168 11.2695 0.916992 11.1094 0.916992H8.43164C8.16211 0.916992 7.9375 0.975586 7.75781 1.09277C7.57812 1.20605 7.43359 1.38574 7.32422 1.63184L3.69727 9.71191C3.6543 9.79785 3.59766 9.86426 3.52734 9.91113C3.46094 9.9541 3.37891 9.97559 3.28125 9.97559H0.890625C0.726562 9.97559 0.587891 10.0283 0.474609 10.1338C0.365234 10.2393 0.310547 10.3701 0.310547 10.5264C0.310547 10.6826 0.365234 10.8135 0.474609 10.9189C0.587891 11.0283 0.726562 11.083 0.890625 11.083ZM7.57031 11.083H11.1094C11.2695 11.083 11.4043 11.0303 11.5137 10.9248C11.627 10.8193 11.6836 10.6885 11.6836 10.5322C11.6836 10.376 11.627 10.2451 11.5137 10.1396C11.4043 10.0342 11.2695 9.98145 11.1094 9.98145H7.57031C7.40625 9.98145 7.26953 10.0342 7.16016 10.1396C7.05078 10.2451 6.99609 10.376 6.99609 10.5322C6.99609 10.6885 7.05078 10.8193 7.16016 10.9248C7.27344 11.0303 7.41016 11.083 7.57031 11.083Z"
                fill="currentColor"
            />
        </svg>
    );
}
WindowsOptionIcon.displayName = "WindowsOptionIcon";
