import React, { useState, useEffect, useRef, type JSX } from "react";
import classNames from "classnames";
import { scrollToRef } from "@internal/utils-browser";
import { ExpandingButton } from "../Buttons";
import type { ExpandingButtonHandles } from "../Buttons";
import styles from "./ValueExpandingDropdown.module.scss";

interface Option {
    value: string;
    name: string;
}

interface Props {
    /** Title of the button, also used to set info for accessability */
    title: string;

    /** The currently selected option */
    selected: Option;

    /** For overriding styles */
    className?: string;

    /** for button styles */
    buttonClassName?: string;

    /** Any offset in pixels that needs to be added for the auto scroll, example if there is a search bar
     * @default 0
     */
    scrollOffset?: number;

    /** Whether or not the button is disabled
     * @default false
     */
    isDisabled?: boolean;

    /** Whether or not to display the chevron */
    showArrow?: boolean;

    content: JSX.Element;

    onClick?: () => void;
}

/**
 * This provides a select with a free entry text field
 */
export function ValueExpandingDropdown({
    title,
    selected,
    buttonClassName,
    className,
    isDisabled,
    scrollOffset = 0,
    showArrow = true,
    content,
    onClick
}: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const buttonRef = useRef<ExpandingButtonHandles>(null);
    const selectedOptionRef = useRef<HTMLButtonElement>(null);
    const optionListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && selectedOptionRef.current) {
            scrollToRef(optionListRef, selectedOptionRef, scrollOffset);
        }
    }, [isOpen, scrollOffset]);

    function onClose() {
        setIsOpen(false);
    }

    function onOpen() {
        onClick?.();
        setIsOpen(true);
    }

    return (
        <ExpandingButton
            buttonClassName={buttonClassName}
            className={className}
            ref={buttonRef}
            content={content}
            title={title}
            isDisabled={isDisabled}
            isLoading={false}
            onClose={onClose}
            onOpen={onOpen}
            showArrow={showArrow}
        >
            <div className={classNames(styles.dropdownContainer)}>{selected.name}</div>
        </ExpandingButton>
    );
}
ValueExpandingDropdown.displayName = "ValueExpandingDropdown";
