import React from "react";

export function SafetyIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" {...props}>
            <rect width="24" height="24" rx="12" fill="#8BBFB7" fillOpacity="0.2" />
            <rect x="20" y="12" width="1" height="16" rx="0.5" transform="rotate(90 20 12)" fill="#8BBFB7" />
        </svg>
    );
}
SafetyIcon.displayName = "SafetyIcon";
