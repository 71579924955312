import React from "react";

export function ItemAlignmentCenterVerticallyIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m2.75 2.5c-0.40469 0-0.75 0.34531-0.75 0.75v4.2949l-1.0254 0.0019531c-0.34518 0.001077-0.62413 0.28177-0.62305 0.62695 0.001077 0.34518 0.28177 0.62413 0.62695 0.62305l1.0215-0.0019531v3.9551c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-3.9727l2-0.0058594v1.9785c0 0.40469 0.34531 0.75 0.75 0.75h3.5c0.40469 0 0.75-0.34531 0.75-0.75v-1.9961l1.1562-0.0039062c0.34518-0.0010769 0.62413-0.28177 0.62305-0.62695-0.001077-0.34518-0.28177-0.62413-0.62695-0.62305l-1.1523 0.0039062v-1.7539c0-0.40469-0.34531-0.75-0.75-0.75h-3.5c-0.40469 0-0.75 0.34531-0.75 0.75v1.7715l-2 0.0058594v-4.2773c0-0.40469-0.34531-0.75-0.75-0.75h-3.5zm0.5 1.25h2.5v8.5h-2.5v-8.5zm7 2.5h2.5v4h-2.5v-4z"
                fill="currentColor"
            />
        </svg>
    );
}
ItemAlignmentCenterVerticallyIcon.displayName = "ItemAlignmentCenterVerticallyIcon";
