import React from "react";

export function InvertColorsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
            <rect width="11" height="11" rx="2" fill="black" />
            <path d="M3.16667 1.5H8.79289L1.5 8.79289V2.75L3.16667 1.5Z" fill="#F8F8F8" stroke="#F8F8F8" />
        </svg>
    );
}
InvertColorsIcon.displayName = InvertColorsIcon;
