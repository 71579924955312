import React, { HTMLProps } from "react";
import classnames from "classnames";
import styles from "./LeftSidebar.module.scss";

interface LeftSidebarProps extends HTMLProps<HTMLDivElement> {
    /** Decides whether the sidebar should be in a visible state */
    visible: boolean;
}

export function LeftSidebar({ children, className, visible, ...props }: LeftSidebarProps) {
    return (
        <div {...props} className={classnames(styles.leftSidebar, className)} data-testid="sidebar" id="left-sidebar">
            {children}
        </div>
    );
}

LeftSidebar.displayName = "LeftSidebar";
