import React from "react";
import { Typography, Icon, Button, Box, BoxProps } from "@vp/swan";
import classNames from "classnames";
import styles from "./StandardSheetHeader.module.scss";

type Props = BoxProps & {
    /** Optional title for the sheet, that will be centered */
    sheetTitle?: string;

    /** Optional Action the back button should take */
    onBackClick?: () => void;

    /** Optional Action the close button should take */
    onCloseClick?: () => void;
    backButtonAriaLabel: string;
    closeButtonAriaLabel: string;
};

export function StandardSheetHeader({
    className,
    onBackClick,
    onCloseClick,
    sheetTitle,
    backButtonAriaLabel,
    closeButtonAriaLabel,
    ...restOfProps
}: Props) {
    return (
        <Box className={classNames(styles.standardSheetHeader, className)} {...restOfProps}>
            {onBackClick && (
                <Button
                    className={styles.standardSheetHeaderBack}
                    skin="unstyled"
                    data-testid="sheet-back-button"
                    onClick={onBackClick}
                    aria-label={backButtonAriaLabel}
                    p={"5"}
                >
                    <Icon className={styles.standardSheetHeaderIcon} iconType="chevronLeft" />
                </Button>
            )}
            {sheetTitle && (
                <Typography
                    compactMode
                    className={styles.standardSheetHeaderTitle}
                    fontSize="x2large"
                    fontWeight="bold"
                    textAlign="center"
                >
                    {sheetTitle}
                </Typography>
            )}
            {!onBackClick && onCloseClick && (
                <Button
                    className={styles.standardSheetHeaderClose}
                    skin="unstyled"
                    onClick={onCloseClick}
                    data-testid="mobileSheetCloseButton"
                    aria-label={closeButtonAriaLabel}
                    p={"5"}
                >
                    <Icon className={styles.standardSheetHeaderIcon} iconType="close" fontSize="x2large" />
                </Button>
            )}
        </Box>
    );
}
StandardSheetHeader.displayName = "StandardSheetHeader";
