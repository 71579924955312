import React from "react";

export function PngIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" viewBox="0 0 24 24" {...props}>
            <path
                d="m3 3c0-1.65685 1.34315-3 3-3h10.7574c.7956 0 1.5587.316071 2.1213.87868l4.2426 4.24264c.5626.56261.8787 1.32567.8787 2.12132v13.75736c0 1.6569-1.3431 3-3 3h-15c-1.65685 0-3-1.3431-3-3z"
                fill="#12b889"
            />
            <path
                d="m24 7.49993h-4.5001c-1.6568 0-3-1.34315-3-3v-4.49993h.2575c.7921 0 1.5519.313258 2.1137.871169l4.2576 4.257601c.558.56188.8713 1.32173.8713 2.11387z"
                fill="#05986f"
            />
            <path
                d="m0 11.4c0-1.3255 1.07452-2.4 2.4-2.4h16.2c1.3255 0 2.4 1.0745 2.4 2.4v5.7c0 1.3255-1.0745 2.4-2.4 2.4h-16.2c-1.32548 0-2.4-1.0745-2.4-2.4z"
                fill="#097451"
            />
            <g fill="#fff">
                <path d="m16.8326 10.5234c-.9821.2214-1.8326 1.0537-1.8326 2.3771v2.8495c0 .6977.2765 1.2727.7276 1.6636.4358.3778.9927.5489 1.5224.5489.5298 0 1.0866-.1711 1.5225-.5489.451-.3909.7275-.9658.7275-1.6636v-2.25h-2.25v1.5h.75v.75c0 .2772-.0985.4335-.2099.5301-.1267.1098-.3198.1824-.5401.1824-.2202 0-.4134-.0726-.54-.1824-.1115-.0966-.21-.2528-.21-.5301v-2.8495c0-.5704.3128-.835.6624-.9138.4061-.0915.7747.076.9224.3596l1.3304-.6926c-.5257-1.0099-1.6571-1.3389-2.5826-1.1303z" />
                <path
                    clipRule="evenodd"
                    d="m1.5 10.5h2.025c1.52082 0 2.46432 1.0635 2.49352 2.2313.01422.5691-.1932 1.15-.64087 1.5868-.45168.4407-1.09214.6819-1.85265.6819h-.525v3h-1.5zm1.5 3h.525c.43948 0 .68028-.1338.80516-.2556.12889-.1257.19335-.2948.18882-.4757-.0083-.3322-.2648-.7687-.99398-.7687h-.525z"
                    fillRule="evenodd"
                />
                <path d="m12 10.5h1.5v7.5h-1.6246l-2.8754-4.7923v4.7923h-1.5v-7.5h1.62464l2.87536 4.7923z" />
            </g>
        </svg>
    );
}
PngIcon.displayName = "PngIcon";
