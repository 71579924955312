import React from "react";

type Props = React.SVGProps<SVGSVGElement>;
export function ResizeIcon(props: Props) {
    return (
        <svg viewBox="0 0 7 12" fill="none" {...props}>
            <circle cx="1" cy="1" r="1" fill="currentColor" />
            <circle cx="6" cy="1" r="1" fill="currentColor" />
            <circle cx="1" cy="6" r="1" fill="currentColor" />
            <circle cx="6" cy="6" r="1" fill="currentColor" />
            <circle cx="1" cy="11" r="1" fill="currentColor" />
            <circle cx="6" cy="11" r="1" fill="currentColor" />
        </svg>
    );
}
ResizeIcon.displayName = "ResizeIcon";
