import React from "react";

export function GridsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" {...props}>
            <rect opacity="0.1" x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#017EB4" />
            <rect width="24" height="24" rx="12" fill="#017EB4" fillOpacity="0.1" />
            <line x1="8.5" y1="4" x2="8.5" y2="20" stroke="#017EB4" />
            <line x1="4" y1="15.5" x2="20" y2="15.5" stroke="#017EB4" />
            <line x1="15.5" y1="4" x2="15.5" y2="20" stroke="#017EB4" />
            <line x1="4" y1="7.5" x2="20" y2="7.5" stroke="#017EB4" />
        </svg>
    );
}
GridsIcon.displayName = "GridsIcon";
