import React from "react";

export function MailingServiceActiveIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" viewBox="0 0 27 27" {...props}>
            <circle cx="13.467" cy="13.499" r="12.796" fill="#318643"></circle>
            <circle cx="13.467" cy="13.499" r="12.296" stroke="#000" strokeOpacity="0.1"></circle>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.258"
                d="M6.794 14.389l3.61 3.707 8.23-8.457"
            ></path>
        </svg>
    );
}
MailingServiceActiveIcon.displayName = "MailingServiceActiveIcon";
