// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function LockIconPremiumFinish(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width="16" height="16" fill="white" />
            <rect width="10" height="8" rx="1" transform="matrix(-1 0 0 1 13 6)" stroke="#666666" strokeWidth="1.5" />
            <path d="M5 6V5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5V6" stroke="#666666" strokeWidth="1.5" />
        </svg>
    );
}
LockIconPremiumFinish.displayName = "LockIconPremiumFinish";
