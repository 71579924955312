import React from "react";

export function CheckmarkIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M1.58348 8.14855L5.49553 12.1667L14.4168 3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
CheckmarkIcon.displayName = "CheckmarkIcon";
