import React from "react";

export function AnimatedDesignTechIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 1615 1615"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            {...props}
        >
            <defs></defs>
            <g id="Layer_x0020_1">
                <g id="Cube">
                    <g id="Inner_x0020_Cube">
                        <polygon points="350,605 699,404 699,807 350,1009 " fill="#100154"></polygon>
                        <polygon points="1049,605 699,404 699,807 1049,1009 " fill="#1A7E00"></polygon>
                        <polygon points="699,807 1049,1009 699,1211 350,1009 " fill="#790803"></polygon>
                    </g>
                    <polyline points="1398,795 1049,1009 1049,605 699,404 1049,202 " />
                    <polygon points="1398,404 1398,807 1049,1009 1049,605 699,404 1049,202 " fill="#00A202"></polygon>
                    <polygon points="1398,1211 1398,807 1049,1009 1049,1413 " fill="#FFF212"></polygon>
                    <polygon points="350,1413 350,1009 699,1211 1049,1009 1049,1413 699,1615 " fill="#EC2202"></polygon>
                    <polygon points="0,1211 0,807 350,1009 350,1413 " fill="#EC268F"></polygon>
                    <polygon points="0,807 0,404 350,202 699,404 350,605 350,1009 " fill="#00268f"></polygon>
                    <polygon points="699,404 350,202 699,0 1049,202 " fill="#00afef"></polygon>
                </g>
            </g>
        </svg>
    );
}

AnimatedDesignTechIcon.displayName = "AnimatedDesignTechIcon";
