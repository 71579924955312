// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function BackgroundRemovalIcon(props: Props) {
    return (
        <svg width="24px" height="24px" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill="#fff" d="M0 .499h32v32H0z" />
            <path
                d="M1 2.499a1 1 0 011-1h1.8v2.8H1v-1.8zM1 7.1h2.8v2.8H1zM1 12.7h2.8v2.8H2a1 1 0 01-1-1v-1.8zM3.8 4.299h2.8v2.8H3.8zM3.8 9.899h2.8v2.8H3.8zM6.6 1.499h2.8v2.8H6.6zM6.6 7.1h2.8v2.8H6.6zM6.6 12.699h2.8v2.8H6.6zM9.4 4.299h2.8v2.8H9.4zM9.4 9.899h2.8v2.8H9.4zM12.2 1.499H14a1 1 0 011 1v1.8h-2.8v-2.8zM12.2 7.1H15v2.8h-2.8zM12.2 12.7H15v1.8a1 1 0 01-1 1h-1.8v-2.8z"
                fill="currentColor"
            />
        </svg>
    );
}
BackgroundRemovalIcon.displayName = "BackgroundRemovalIcon";
