import React from "react";

export function LayersIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path
                d="M2.12426 5.22361C1.94 5.13148 1.94 4.86852 2.12426 4.77639L7.8882 1.89443C7.95858 1.85924 8.04142 1.85924 8.1118 1.89443L13.8757 4.77639C14.06 4.86852 14.06 5.13148 13.8757 5.22361L8.1118 8.10557C8.04142 8.14076 7.95858 8.14076 7.8882 8.10557L2.12426 5.22361Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path
                d="M2 8.5L7.55279 11.2764C7.83431 11.4172 8.16569 11.4172 8.44721 11.2764L14 8.5"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
            <path
                d="M2 11.5L7.55279 14.2764C7.83431 14.4172 8.16569 14.4172 8.44721 14.2764L14 11.5"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}
LayersIcon.displayName = "LayersIcon";
