import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function TrashCanIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M10.3756 13.4996H5.62416C4.87788 13.4996 4.31299 12.9276 4.31299 12.2716V5.55469H11.6868V12.2698C11.6868 12.928 11.1215 13.4996 10.3756 13.4996Z"
                stroke="currentColor"
            />
            <path
                d="M9.74952 2.97663L9.49133 2.32103C9.41618 2.12764 9.22929 2 9.02119 2H6.97881C6.77071 2 6.58382 2.12764 6.50674 2.32103L6.24855 2.97663H3V3.97648H13V2.97663H9.74952Z"
                fill="currentColor"
            />
        </svg>
    );
}
TrashCanIcon.displayName = "TrashCanIcon";
