import React from "react";

export function FullCurveDownIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M2.46816 6.57444C2.3712 6.31588 2.083 6.18488 1.82444 6.28184C1.56588 6.3788 1.43488 6.667 1.53184 6.92556L2.46816 6.57444ZM14.4682 6.92556C14.5651 6.667 14.4341 6.3788 14.1756 6.28184C13.917 6.18488 13.6288 6.31588 13.5318 6.57444L14.4682 6.92556ZM1.53184 6.92556C3.77306 12.9022 12.2269 12.9022 14.4682 6.92556L13.5318 6.57444C11.6151 11.6859 4.38495 11.6859 2.46816 6.57444L1.53184 6.92556Z"
                fill="currentColor"
            />
        </svg>
    );
}
FullCurveDownIcon.displayName = "FullCurveDownIcon";
