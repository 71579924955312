import React from "react";

export function ExpandingButtonDownArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M4 6.5L8 10.5C8 10.5 10.4762 8.02381 12 6.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
ExpandingButtonDownArrow.displayName = "ExpandingButtonDownArrow";
