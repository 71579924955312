import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./SidebarItemGrid.module.scss";

export interface SidebarItemGridProps {
    children: ReactNode;
    className?: string;
    isThreeColumns?: boolean;
    isOldStyle?: boolean;
    dataTestId?: string;
}

export const SidebarItemGrid = ({
    children,
    className,
    isThreeColumns = true,
    isOldStyle = false,
    dataTestId
}: SidebarItemGridProps) => {
    return (
        <div
            className={classnames("sidebar-item-grid", styles.sidebarItemGrid, className, {
                [styles.sidebarItemGridColumns3]: isThreeColumns,
                [styles.sidebarItemGridColumns4]: !isThreeColumns || isOldStyle,
                [styles.sidebarItemGridOldStyle]: isOldStyle
            })}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
};
