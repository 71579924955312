import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function ThemeIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_688_931)">
                <path
                    d="M8.5971 14.6153C11.4059 14.6153 13.2807 13.6213 13.2807 12.1535C13.2807 10.8392 12.1598 10.5456 12.1598 9.77172C12.1598 8.70425 16.0361 8.49075 16.0361 5.74868C16.0361 3.03997 13.2406 1.25195 9.09084 1.25195C3.64004 1.25195 -0.0360718 3.97401 -0.0360718 8.05042C-0.0360718 11.9734 3.45323 14.6153 8.5971 14.6153ZM8.5971 13.4745C4.08037 13.4745 1.10479 11.2995 1.10479 8.05042C1.10479 4.64785 4.26718 2.39281 9.09084 2.39281C12.5934 2.39281 14.8952 3.75384 14.8952 5.74868C14.8952 8.0771 10.9923 7.76354 10.9923 9.77841C10.9923 10.9193 12.1398 11.2795 12.1398 12.0668C12.1398 12.9141 10.7454 13.4745 8.5971 13.4745ZM8.05001 11.9C9.06413 11.9 9.89809 11.066 9.89809 10.0453C9.89809 9.03784 9.06413 8.20388 8.05001 8.20388C7.03596 8.20388 6.20197 9.03784 6.20197 10.0453C6.20197 11.066 7.03596 11.9 8.05001 11.9ZM8.05001 11.1261C7.45626 11.1261 6.97591 10.6457 6.97591 10.0453C6.97591 9.38476 7.48297 8.99111 8.03669 8.98449C8.6038 8.97779 9.12418 9.36475 9.12418 10.0453C9.12418 10.6457 8.65046 11.1261 8.05001 11.1261Z"
                    fill="black"
                    fillOpacity="0.85"
                />
                <path
                    d="M3.33306 8.96471C3.94685 8.96471 4.44056 8.45765 4.44056 7.84384C4.44056 7.23004 3.94685 6.73633 3.33306 6.73633C2.72593 6.73633 2.22556 7.23004 2.22556 7.84384C2.22556 8.45765 2.72593 8.96471 3.33306 8.96471Z"
                    fill="black"
                    fillOpacity="0.85"
                />
                <path
                    d="M5.76829 6.82979C6.54221 6.82979 7.16937 6.20932 7.16937 5.42206C7.16937 4.65481 6.53554 4.04102 5.76829 4.04102C4.98771 4.04102 4.36057 4.65481 4.36057 5.42206C4.36057 6.20932 4.98771 6.82979 5.76829 6.82979Z"
                    fill="black"
                    fillOpacity="0.85"
                />
                <path
                    d="M9.15084 5.74836C9.77793 5.74836 10.2717 5.24798 10.2717 4.62084C10.2717 4.00038 9.77793 3.5 9.15084 3.5C8.537 3.5 8.02332 4.00038 8.02332 4.62084C8.02332 5.24798 8.537 5.74836 9.15084 5.74836Z"
                    fill="black"
                    fillOpacity="0.85"
                />
                <path
                    d="M12.1999 6.17586C12.7003 6.17586 13.1006 5.76889 13.1006 5.26184C13.1006 4.76146 12.7003 4.35449 12.1999 4.35449C11.6929 4.35449 11.2792 4.76146 11.2792 5.26184C11.2792 5.76889 11.6929 6.17586 12.1999 6.17586Z"
                    fill="black"
                    fillOpacity="0.85"
                />
            </g>
            <defs>
                <clipPath id="clip0_688_931">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
ThemeIcon.displayName = "ThemeIcon";
