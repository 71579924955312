import React, { forwardRef } from "react";
import classNames from "classnames";
import { PolymorphicComponentPropWithRef } from "../PolymorphicComponent";
import { Button } from "./Button";
import styles from "./ToolbarButton.module.scss";

type ToolbarButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C>;

type ToolbarButtonComponent = (<C extends React.ElementType = typeof Button>(
    props: ToolbarButtonProps<C> & {
        /* add custom props here */
    }
) => React.ReactNode) & {
    displayName?: string;
};

export const ToolbarButton: ToolbarButtonComponent = forwardRef((props, ref) => {
    const { as: Component = Button, className, ...rest } = props;

    return <Component ref={ref} className={classNames(styles.toolbarButton, className)} {...rest} />;
});

ToolbarButton.displayName = "ToolbarButton";
