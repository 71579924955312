// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function EditShapeIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M4.06637 12.0966L3.90262 9.51047L12.413 1.00007L14.9991 3.5862L6.48875 12.0966L4.06637 12.0966Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <line x1="9.93072" y1="3.2685" x2="12.6582" y2="5.99599" stroke="currentColor" strokeWidth="1.5" />
                <line
                    x1="1.75"
                    y1="15.25"
                    x2="14.25"
                    y2="15.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
EditShapeIcon.displayName = "EditShapeIcon";
