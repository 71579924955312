import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function AddTableColumnLeftIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M6 14C6.55228 14 7 13.5523 7 13L7 3C7 2.44772 6.55228 2 6 2L3 2C2.44771 2 2 2.44772 2 3L2 13C2 13.5523 2.44772 14 3 14L6 14Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path d="M10 8L14 8M12 10L12 6" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
AddTableColumnLeftIcon.displayName = "AddTableColumnLeftIcon";
