import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function AddTableRowAboveIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M2 6C2 6.55228 2.44772 7 3 7H13C13.5523 7 14 6.55228 14 6V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3V6Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path d="M8 10L8 14M6 12L10 12" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
AddTableRowAboveIcon.displayName = "AddTableRowAboveIcon";
