import React from "react";

export function SlightCurveUpIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M13.7226 10.666C13.9524 10.8192 14.2628 10.7571 14.416 10.5274C14.5692 10.2976 14.5071 9.98715 14.2774 9.83397L13.7226 10.666ZM1.72265 9.83397C1.49289 9.98715 1.4308 10.2976 1.58397 10.5273C1.73715 10.7571 2.04759 10.8192 2.27735 10.666L1.72265 9.83397ZM14.2774 9.83397C10.4761 7.2998 5.52391 7.2998 1.72265 9.83397L2.27735 10.666C5.74271 8.35579 10.2573 8.35579 13.7226 10.666L14.2774 9.83397Z"
                fill="currentColor"
            />
        </svg>
    );
}
SlightCurveUpIcon.displayName = "SlightCurveUpIcon";
