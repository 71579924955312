import React from "react";

type Props = React.SVGProps<SVGSVGElement>;
export function ShortcutUpArrowIcon(props: Props) {
    return (
        <svg viewBox="0 0 10 14" fill="none" {...props}>
            <path
                d="M4.99658 13.1216C5.18799 13.1216 5.34294 13.0623 5.46143 12.9438C5.58447 12.8254 5.646 12.6704 5.646 12.479V4.22119L5.5708 2.19775L5.16748 2.36865L7.40967 4.84326L8.87939 6.28564C8.93864 6.34489 9.007 6.39046 9.08447 6.42236C9.16195 6.44971 9.24626 6.46338 9.3374 6.46338C9.51514 6.46338 9.66325 6.40413 9.78174 6.28564C9.90023 6.16715 9.95947 6.01904 9.95947 5.84131C9.95947 5.66813 9.89111 5.5109 9.75439 5.36963L5.48193 1.09033C5.41357 1.01742 5.33838 0.965007 5.25635 0.933105C5.17432 0.896647 5.08773 0.878418 4.99658 0.878418C4.90999 0.878418 4.82568 0.896647 4.74365 0.933105C4.66162 0.965007 4.58643 1.01742 4.51807 1.09033L0.23877 5.36963C0.106608 5.5109 0.0405273 5.66813 0.0405273 5.84131C0.0405273 6.01904 0.0974935 6.16715 0.211426 6.28564C0.329915 6.40413 0.480306 6.46338 0.662598 6.46338C0.753743 6.46338 0.838053 6.44971 0.915527 6.42236C0.993001 6.39046 1.06136 6.34489 1.12061 6.28564L2.59033 4.84326L4.81885 2.36865L4.4292 2.19775L4.354 4.22119V12.479C4.354 12.6704 4.41325 12.8254 4.53174 12.9438C4.65023 13.0623 4.80518 13.1216 4.99658 13.1216Z"
                fill="currentColor"
            />
        </svg>
    );
}
ShortcutUpArrowIcon.displayName = "ShortcutUpArrowIcon";
