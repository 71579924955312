import React from "react";

type Props = React.SVGProps<SVGSVGElement>;
export function KeyboardIcon(props: Props) {
    return (
        <svg viewBox="0 0 20 13" fill="none" {...props}>
            <path
                d="M3.14844 12.4531H17.1172C17.8672 12.4531 18.4297 12.2656 18.8047 11.8906C19.1849 11.5156 19.375 10.9583
                19.375 10.2188V3.10156C19.375 2.36198 19.1849 1.80469 18.8047 1.42969C18.4297 1.05469 17.8672 0.867188 17.1172
                0.867188H3.14844C2.39323 0.867188 1.82552 1.05469 1.44531 1.42969C1.07031 1.80469 0.882812 2.36198 0.882812
                3.10156V10.2188C0.882812 10.9635 1.07031 11.5234 1.44531 11.8984C1.82552 12.2682 2.39323 12.4531 3.14844
                12.4531ZM3.16406 11.3828C2.77865 11.3828 2.48177 11.2786 2.27344 11.0703C2.07031 10.862 1.96875 10.5599 1.96875
                10.1641V3.17188C1.96875 2.76562 2.07031 2.46094 2.27344 2.25781C2.48177 2.05469 2.77865 1.95312 3.16406 1.95312H17.0938C17.4792
                1.95312 17.7734 2.05469 17.9766 2.25781C18.1849 2.46094 18.2891 2.76562 18.2891 3.17188V10.1641C18.2891
                10.5599 18.1849 10.862 17.9766 11.0703C17.7734 11.2786 17.4792 11.3828 17.0938 11.3828H3.16406ZM3.84375
                4.92969H4.49219C4.71615 4.92969 4.82812 4.82292 4.82812 4.60938V3.94531C4.82812 3.73177 4.71615 3.625
                4.49219 3.625H3.84375C3.625 3.625 3.51562 3.73177 3.51562 3.94531V4.60938C3.51562 4.82292 3.625 4.92969
                3.84375 4.92969ZM6.21875 4.92969H6.875C7.09375 4.92969 7.20312 4.82292 7.20312 4.60938V3.94531C7.20312
                3.73177 7.09375 3.625 6.875 3.625H6.21875C6 3.625 5.89062 3.73177 5.89062 3.94531V4.60938C5.89062 4.82292
                6 4.92969 6.21875 4.92969ZM8.59375 4.92969H9.25C9.46354 4.92969 9.57031 4.82292 9.57031 4.60938V3.94531C9.57031
                3.73177 9.46354 3.625 9.25 3.625H8.59375C8.375 3.625 8.26562 3.73177 8.26562 3.94531V4.60938C8.26562 4.82292
                8.375 4.92969 8.59375 4.92969ZM10.9688 4.92969H11.625C11.8385 4.92969 11.9453 4.82292 11.9453 4.60938V3.94531C11.9453
                3.73177 11.8385 3.625 11.625 3.625H10.9688C10.75 3.625 10.6406 3.73177 10.6406 3.94531V4.60938C10.6406 4.82292 10.75
                4.92969 10.9688 4.92969ZM13.3438 4.92969H14C14.2188 4.92969 14.3281 4.82292 14.3281 4.60938V3.94531C14.3281 3.73177
                14.2188 3.625 14 3.625H13.3438C13.125 3.625 13.0156 3.73177 13.0156 3.94531V4.60938C13.0156 4.82292 13.125 4.92969
                13.3438 4.92969ZM15.7188 4.92969H16.375C16.5938 4.92969 16.7031 4.82292 16.7031 4.60938V3.94531C16.7031 3.73177
                16.5938 3.625 16.375 3.625H15.7188C15.5052 3.625 15.3984 3.73177 15.3984 3.94531V4.60938C15.3984 4.82292 15.5052
                4.92969 15.7188 4.92969ZM3.84375 7.30469H4.49219C4.71615 7.30469 4.82812 7.19792 4.82812 6.98438V6.32031C4.82812
                6.10677 4.71615 6 4.49219 6H3.84375C3.625 6 3.51562 6.10677 3.51562 6.32031V6.98438C3.51562 7.19792 3.625 7.30469
                3.84375 7.30469ZM6.21875 7.30469H6.875C7.09375 7.30469 7.20312 7.19792 7.20312 6.98438V6.32031C7.20312 6.10677
                7.09375 6 6.875 6H6.21875C6 6 5.89062 6.10677 5.89062 6.32031V6.98438C5.89062 7.19792 6 7.30469 6.21875
                7.30469ZM8.59375 7.30469H9.25C9.46354 7.30469 9.57031 7.19792 9.57031 6.98438V6.32031C9.57031 6.10677 9.46354
                6 9.25 6H8.59375C8.375 6 8.26562 6.10677 8.26562 6.32031V6.98438C8.26562 7.19792 8.375 7.30469 8.59375 7.30469ZM10.9688
                7.30469H11.625C11.8385 7.30469 11.9453 7.19792 11.9453 6.98438V6.32031C11.9453 6.10677 11.8385 6 11.625 6H10.9688C10.75
                6 10.6406 6.10677 10.6406 6.32031V6.98438C10.6406 7.19792 10.75 7.30469 10.9688 7.30469ZM13.3438 7.30469H14C14.2188
                7.30469 14.3281 7.19792 14.3281 6.98438V6.32031C14.3281 6.10677 14.2188 6 14 6H13.3438C13.125 6 13.0156 6.10677 13.0156
                6.32031V6.98438C13.0156 7.19792 13.125 7.30469 13.3438 7.30469ZM15.7188 7.30469H16.375C16.5938 7.30469 16.7031 7.19792 16.7031
                6.98438V6.32031C16.7031 6.10677 16.5938 6 16.375 6H15.7188C15.5052 6 15.3984 6.10677 15.3984 6.32031V6.98438C15.3984 7.19792
                15.5052 7.30469 15.7188 7.30469ZM3.84375 9.6875H4.49219C4.71615 9.6875 4.82812 9.57552 4.82812 9.35156V8.70312C4.82812 8.47917
                4.71615 8.36719 4.49219 8.36719H3.84375C3.625 8.36719 3.51562 8.47917 3.51562 8.70312V9.35156C3.51562 9.57552 3.625 9.6875
                3.84375 9.6875ZM6.28125 9.6875H13.9375C14.1979 9.6875 14.3281 9.55729 14.3281 9.29688V8.76562C14.3281 8.5 14.1979 8.36719
                13.9375 8.36719H6.28125C6.02083 8.36719 5.89062 8.5 5.89062 8.76562V9.29688C5.89062 9.55729 6.02083 9.6875 6.28125 9.6875ZM15.7188
                9.6875H16.375C16.5938 9.6875 16.7031 9.57552 16.7031 9.35156V8.70312C16.7031 8.47917 16.5938 8.36719 16.375 8.36719H15.7188C15.5052
                8.36719 15.3984 8.47917 15.3984 8.70312V9.35156C15.3984 9.57552 15.5052 9.6875 15.7188 9.6875Z"
                fill="currentColor"
            />
        </svg>
    );
}
KeyboardIcon.displayName = "KeyboardIcon";
