import React from "react";

type Props = React.SVGProps<SVGSVGElement>;
export function ImageRotateIcon(props: Props) {
    return (
        <svg viewBox="0 0 14 15" fill="none" {...props}>
            <rect
                x="2.5"
                y="7.51514"
                width="8"
                height="7.09091"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 3.57576C10 3.57576 14 3.57576 14 7.51515M8.5 2L6.5 3.57576L8.5 5.15152"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
ImageRotateIcon.displayName = ImageRotateIcon;
