import React from "react";

export function JoystickIcon() {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.73833 11.553L8.51959 11.553V8.50397L11.5306 8.50397L11.5306 9.75385C11.5306 9.92703 11.5807 10.0706 11.681 10.1845C11.7858 10.303 11.9111 10.3668 12.057 10.3759C12.2074 10.385 12.3509 10.3235 12.4876 10.1913L14.2724 8.42878C14.4136 8.29662 14.482 8.14395 14.4774 7.97077C14.4774 7.80215 14.4068 7.65176 14.2655 7.5196L12.4876 5.80184C12.3509 5.67423 12.2074 5.61499 12.057 5.6241C11.9111 5.63322 11.7858 5.69246 11.681 5.80184C11.5807 5.91577 11.5306 6.0616 11.5306 6.23934V7.45807L8.51959 7.45807V4.44705H9.73833C9.91606 4.44705 10.0619 4.39692 10.1758 4.29666C10.2852 4.19184 10.3444 4.06651 10.3536 3.92068C10.3627 3.77029 10.3034 3.62673 10.1758 3.49002L8.45806 1.71213C8.3259 1.57085 8.17551 1.50021 8.00689 1.50021C7.83371 1.49566 7.68105 1.56402 7.54888 1.70529L5.78631 3.49002C5.65415 3.62673 5.59263 3.77029 5.60174 3.92068C5.61086 4.06651 5.67466 4.19184 5.79315 4.29666C5.90708 4.39692 6.05064 4.44705 6.22381 4.44705L7.47369 4.44705L7.47369 7.45807L4.42471 7.45807L4.42471 6.23934C4.42471 6.0616 4.37458 5.91577 4.27432 5.80184C4.1695 5.69246 4.04417 5.63322 3.89834 5.6241C3.74795 5.61499 3.6044 5.67423 3.46768 5.80184L1.68979 7.5196C1.54851 7.65176 1.47787 7.80215 1.47787 7.97077C1.47332 8.14395 1.54168 8.29662 1.68295 8.42878L3.46768 10.1913C3.6044 10.3235 3.74795 10.385 3.89834 10.3759C4.04417 10.3668 4.1695 10.303 4.27432 10.1845C4.37458 10.0706 4.42471 9.92702 4.42471 9.75385V8.50397H7.47369V11.553H6.22381C6.05064 11.553 5.90708 11.6031 5.79315 11.7033C5.67466 11.8082 5.61086 11.9335 5.60174 12.0793C5.59263 12.2297 5.65415 12.3733 5.78631 12.51L7.54888 14.2947C7.68105 14.436 7.83371 14.5043 8.00689 14.4998C8.17551 14.4998 8.3259 14.4291 8.45806 14.2879L10.1758 12.51C10.3034 12.3733 10.3627 12.2297 10.3536 12.0793C10.3444 11.9335 10.2852 11.8082 10.1758 11.7033C10.0619 11.6031 9.91606 11.553 9.73833 11.553Z"
                fill="currentColor"
            />
        </svg>
    );
}
JoystickIcon.displayName = "JoystickIcon";
