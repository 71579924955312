import React from "react";

export function MaximizeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <line x1="4" y1="8" x2="12" y2="8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="8" y1="12" x2="8" y2="4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
MaximizeIcon.displayName = "MaximizeIcon";
