import React from "react";

export function RenameProjectIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M4.0671 12.498L3.90335 9.91183L12.4138 1.40143L14.9999 3.98756L6.48948 12.498L4.0671 12.498Z"
                    stroke="currentColor"
                />
                <line x1="9.75346" y1="3.84664" x2="12.4809" y2="6.57413" stroke="currentColor" />
                <line x1="1.5" y1="16.3203" x2="14.5" y2="16.3203" stroke="currentColor" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.820312)" />
                </clipPath>
            </defs>
        </svg>
    );
}
RenameProjectIcon.displayName = "RenameProjectIcon";
