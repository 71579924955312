import React, { ReactNode, useState, useRef, forwardRef, useImperativeHandle, Ref, useEffect } from "react";
import { usePopper } from "react-popper";
import classnames from "classnames";
import { Placement, State } from "@popperjs/core";
import { Icon } from "@vp/swan";
import { ExpandingButtonDownArrow } from "../Icons";
import { useClickOutside } from "../hooks";
import { ToolbarButton } from "./ToolbarButton";
import expandingButtonStyles from "./ExpandingButton.module.scss";

export interface ExpandingButtonRenderProps {
    title?: string;
    onClick: () => void;
    isSelected: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    className: string;
    ref: React.RefObject<HTMLButtonElement>;
    tabIndex: number;
    onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

interface BaseExpandingButtonProps extends Omit<React.ComponentProps<typeof ToolbarButton>, "as"> {
    /**
     * Whether or not the button is disabled
     * @default false
     */
    isDisabled?: boolean;
    /**
     * Whether or not the button is loading
     * @default false
     */
    isLoading?: boolean;
    /** Title of the button, also used to set info for accessability.
     * Unnecessary if children has text to be shown instead
     */
    title?: string;
    /**
     * Child elements for button
     * Put in the icon / text expected here.
     */
    children?: ReactNode | ((props: ExpandingButtonRenderProps) => ReactNode);
    /**
     * Content to be used in the expanded area or a callback updating the popper position
     * before rendering content
     */
    content: ReactNode | ((updatePopperPosition: () => Promise<Partial<State>>) => ReactNode);
    /** Provide a callback when the button opens */
    onOpen?: () => void;
    /** Provide a callback when the button closes */
    onClose?: () => void;
    /**
     * Show up/down arrow next to button
     * @default false
     */
    showArrow?: boolean;
    /** className for the container div holding the button and content,
     *  for overriding styles */
    className?: string;
    /** override popper placement */
    overridePopperPlacement?: "top" | "bottom" | "right";
    /** override popper alignment */
    overridePopperAlignment?: "start" | "end" | undefined;
    /** override popper offset x */
    overridePopperOffsetX?: number;
    /** override popper offset y */
    overridePopperOffsetY?: number;
    displayType?: "default" | "singlerow" | "multirow";
    /**
     * Whether the button's children include an input,
     * used for allowing correct keyboard accessibility
     * @default false
     */
    containsInput?: boolean;
    buttonCallback?: (btnRef: HTMLButtonElement | null) => void;
    /**
     * className override for the actual button element being rendered
     */
    buttonClassName?: string;
    /**
     * className override for the expanding container element being rendered
     */
    containerClassName?: string;
    /**
     * className override for the expanding content element being rendered
     */
    contentClassName?: string;
    /**
     * className override for the expanding button down arrow being rendered
     */
    expandingButtonDownArrowClassName?: string;

    component?: React.ElementType;
}

type CloseButtonProps =
    | { showCloseButton?: false; closeButtonAriaLabel: never }
    | { showCloseButton: true; closeButtonAriaLabel: string };

export type ExpandingButtonProps = BaseExpandingButtonProps & CloseButtonProps;

export interface ExpandingButtonHandles {
    close(fireCallback?: boolean): void;
    open(fireCallback?: boolean): void;
    toggle(fireCallback?: boolean): void;
    expanded: boolean;
}

const noop = () => {};

// popper needs to be 12px away from the toolbar
// so need to account for the 7.5px of margin around the button
const DEFAULT_OFFSET_Y = 19.5;

/**
 * This button is responsible the basis for expanding buttons
 */
export const ExpandingButton = forwardRef(
    // This component needs to be a forwardRef for useImperativeHandle to work
    (
        {
            content,
            children,
            isDisabled = false,
            isLoading = false,
            title,
            onClose = noop,
            onOpen = noop,
            showArrow = false,
            className,
            overridePopperPlacement = "bottom",
            overridePopperAlignment,
            overridePopperOffsetX = 0,
            overridePopperOffsetY = DEFAULT_OFFSET_Y,
            displayType = "default",
            showCloseButton = false,
            closeButtonAriaLabel,
            containsInput = false,
            buttonCallback,
            buttonClassName,
            containerClassName,
            contentClassName,
            expandingButtonDownArrowClassName,
            ...rest
        }: ExpandingButtonProps,
        selfRef: Ref<ExpandingButtonHandles>
    ) => {
        const [expanded, setExpanded] = useState(false);
        const [contentClicked, setContentClicked] = useState(false);
        const wrapperReference = useRef<HTMLDivElement>(null);
        const contentReference = useRef<HTMLDivElement>(null);
        const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
        const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
        const btnRef = useRef<HTMLButtonElement>(null);
        const customOffset = React.useMemo(
            () => ({
                name: "offset",
                enabled: true,
                options: { offset: () => [overridePopperOffsetX, overridePopperOffsetY] }
            }),
            [overridePopperOffsetX, overridePopperOffsetY]
        );

        const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
            placement: `${overridePopperPlacement}${
                overridePopperAlignment ? `-${overridePopperAlignment}` : ""
            }` as Placement,
            modifiers: [
                customOffset,
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        padding: {
                            top: 80, // Offset for Poppers that overflow to stay under the GlobalToolbar
                            right: 12,
                            left: 0,
                            bottom: 12
                        }
                    }
                }
            ]
        });

        // Return focus to button when a modal close
        useEffect(() => {
            if (buttonCallback) {
                buttonCallback(btnRef.current);
            }
        }, [buttonCallback, btnRef]);

        useClickOutside(
            [wrapperReference, contentReference],
            async () => {
                if (!contentClicked && expanded) {
                    onClose();
                    setExpanded(false);
                }
            },
            [expanded, contentClicked]
        );

        function onClick() {
            setExpanded(!expanded);
            if (expanded) {
                onClose();
            } else {
                onOpen();
            }
        }

        // Create a handle that the parent can use to close this expanding button
        useImperativeHandle(
            selfRef,
            () => ({
                close: (fireCallback = true) => {
                    setExpanded(false);
                    if (fireCallback) {
                        onClose();
                    }
                },
                open: (fireCallback = true) => {
                    setExpanded(true);
                    if (fireCallback) {
                        onOpen();
                    }
                },
                toggle: (fireCallback = true) => {
                    const nextState = !expanded;
                    setExpanded(nextState);
                    if (fireCallback) {
                        if (nextState) {
                            onOpen();
                        } else {
                            onClose();
                        }
                    }
                },
                expanded
            }),
            [onClose, onOpen, expanded]
        );

        function onContentMouseDown() {
            setContentClicked(true);
        }

        function onContentMouseUp() {
            setContentClicked(false);
        }

        function handleKeyDown(key: string) {
            if ((expanded && key === "Escape") || (containsInput && key === "Enter")) {
                onClick();
            }
        }

        const buttonProps = {
            title: title,
            onClick: onClick,
            isSelected: expanded,
            isDisabled: isDisabled,
            isLoading: isLoading,
            className: classnames("expanding-button", buttonClassName),
            ref: btnRef,
            tabIndex: containsInput ? -1 : 0,
            onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => {
                handleKeyDown(event.key);
            }
        };

        return (
            <div className={className} ref={wrapperReference} style={{ display: "inline-block" }}>
                {typeof children === "function" ? (
                    <div ref={setReferenceElement}>{children(buttonProps)}</div>
                ) : (
                    <div ref={setReferenceElement} className="expanding-button-container">
                        <ToolbarButton {...buttonProps} aria-expanded={expanded} {...rest}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {children}
                                {showArrow && (
                                    <ExpandingButtonDownArrow
                                        aria-hidden={true}
                                        className={classnames(
                                            "dcl-ctx-svg-expand-arrow",
                                            "expandingButton-arrowIconStyle",
                                            {
                                                "expandingButton-expanded": expanded
                                            },
                                            {
                                                [expandingButtonStyles.expandingButtonExpanded]: expanded
                                            },
                                            expandingButtonStyles.expandingButtonArrowIconStyle,
                                            expandingButtonDownArrowClassName
                                        )}
                                    />
                                )}
                            </div>
                        </ToolbarButton>
                    </div>
                )}

                {expanded && (
                    <div
                        ref={setPopperElement}
                        style={styles.popper}
                        className={classnames(
                            "expandingButton-popperContainerStyle",
                            `expandingButton-popperContainerStyle-${displayType}`,
                            `${className}-popper-container`,
                            expandingButtonStyles.expandingButtonPopperContainerStyle,
                            expandingButtonStyles[
                                `expandingButtonPopperContainerStyle${
                                    displayType.charAt(0).toUpperCase() + displayType.slice(1)
                                }`
                            ],
                            containerClassName,
                            className
                        )}
                        {...attributes.popper}
                    >
                        {/* event handlers are mouse-only for opening and closing, skipping a role for this */}
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- not fixing for now as this component will likely go away once we extract and/or update Studio6 for the new IA */}
                        <div
                            ref={contentReference}
                            onMouseDown={onContentMouseDown}
                            onMouseUp={onContentMouseUp}
                            className={classnames(`${className}-popper-content`, contentClassName)}
                        >
                            {showCloseButton && (
                                <Icon
                                    iconType="close"
                                    size="20p"
                                    render={props => (
                                        <button
                                            className={`icon-button ${className}-popper-close-button`}
                                            onClick={() => setExpanded(false)}
                                            aria-label={closeButtonAriaLabel}
                                        >
                                            <img {...props} alt="" />
                                        </button>
                                    )}
                                />
                            )}
                            {React.isValidElement(content)
                                ? content
                                : content instanceof Function && update && content(update)}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

ExpandingButton.displayName = "ExpandingButton";
