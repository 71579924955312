import React from "react";

export function ExpandRightArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M9.20984 1.98096C8.87846 1.98096 8.60983 2.24959 8.60983 2.58096C8.60983 2.91233 8.87846 3.18096 9.20983 3.18096L11.9618 3.18096L8.09819 7.04459C8.09068 7.05138 8.0833 7.0584 8.07605 7.06564L3.03008 12.1116L3.03008 9.36012C3.03008 9.02875 2.76145 8.76012 2.43008 8.76012C2.09871 8.76012 1.83008 9.02875 1.83008 9.36012V13.5603C1.83008 13.7195 1.89329 13.8721 2.00582 13.9846C2.11834 14.0971 2.27136 14.1603 2.43049 14.1603L6.63039 14.1603C6.96176 14.1603 7.23039 13.8917 7.23039 13.5603C7.23039 13.229 6.96176 12.9603 6.63039 12.9603L3.87841 12.9603L7.74203 9.09671C7.74955 9.08992 7.75693 9.0829 7.76417 9.07566L12.8101 4.02968L12.8101 6.78118C12.8101 7.11255 13.0788 7.38118 13.4101 7.38118C13.7415 7.38118 14.0101 7.11255 14.0101 6.78118L14.0101 2.58096C14.0101 2.42183 13.9469 2.26921 13.8344 2.15669C13.7219 2.04417 13.5689 1.98096 13.4097 1.98096H9.20984Z"
                fill="black"
            />
        </svg>
    );
}
ExpandRightArrowIcon.displayName = "ExpandRightArrowIcon";
