import React from "react";

export function ErrorIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Icon Shape Vector">
                <path
                    id="Vector 65"
                    d="M12.0651 1.03502L0.90706 19.831C0.47103 20.5655 0.994502 21.5 1.84199 21.5H24.158C25.0055 21.5 25.529 20.5655 25.0929 19.831L13.9349 1.03502C13.5115 0.32166 12.4885 0.321658 12.0651 1.03502Z"
                    fill="#D24345"
                />
                <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 15.025C12.5922 15.025 12.2573 14.7026 12.2418 14.2951L11.99 7.67424C11.9682 7.10133 12.4267 6.625 13 6.625C13.5734 6.625 14.0319 7.10133 14.0101 7.67424L13.7582 14.2951C13.7427 14.7026 13.4079 15.025 13 15.025ZM13 18.1757C13.5799 18.1757 14.05 17.7056 14.05 17.1257C14.05 16.5458 13.5799 16.0757 13 16.0757C12.4201 16.0757 11.95 16.5458 11.95 17.1257C11.95 17.7056 12.4201 18.1757 13 18.1757Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}

ErrorIcon.displayName = "ErrorIcon";
