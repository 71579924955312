import React from "react";

export function ViewIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 12 10" fill="none" {...props}>
            <path
                d="M7.63 2.95c.6 0 1.09-.37 1.28-.88h1.9c.23 0 .45-.21.45-.48a.46.46 0 0 0-.46-.47H8.92c-.2-.52-.7-.9-1.29-.9-.58 0-1.08.38-1.27.9H.47a.47.47 0 0 0 0 .94h5.9c.19.52.68.9 1.26.9Zm0-.7a.66.66 0 0 1-.65-.66c0-.37.3-.66.65-.66.38 0 .67.29.67.66 0 .37-.29.66-.67.66ZM.45 4.53A.47.47 0 0 0 0 5c0 .26.21.47.45.47H2.4a1.36 1.36 0 0 0 2.55 0h5.83a.47.47 0 1 0 0-.94H4.96a1.36 1.36 0 0 0-2.56 0H.45Zm3.23 1.13A.66.66 0 0 1 3.02 5c0-.37.3-.66.66-.66.37 0 .66.29.66.66 0 .37-.29.66-.66.66Zm3.95 4.11c.6 0 1.1-.37 1.29-.89h1.88c.24 0 .46-.2.46-.47a.47.47 0 0 0-.46-.48H8.91a1.36 1.36 0 0 0-2.55 0H.47a.47.47 0 0 0-.47.48c0 .26.21.47.47.47h5.89c.2.52.7.9 1.27.9Zm0-.7a.66.66 0 0 1-.65-.66c0-.38.3-.66.65-.66.38 0 .67.28.67.66 0 .37-.29.66-.67.66Z"
                fill="currentColor"
            />
        </svg>
    );
}
ViewIcon.displayName = "ViewIcon";
