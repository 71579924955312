import React from "react";

export function SlightCurveDownIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path
                d="M2.27735 7.33397C2.04759 7.1808 1.73715 7.24289 1.58397 7.47265C1.4308 7.70241 1.49289 8.01285 1.72265 8.16603L2.27735 7.33397ZM14.2774 8.16603C14.5071 8.01285 14.5692 7.70241 14.416 7.47265C14.2628 7.24289 13.9524 7.1808 13.7226 7.33397L14.2774 8.16603ZM1.72265 8.16603C5.52391 10.7002 10.4761 10.7002 14.2774 8.16603L13.7226 7.33397C10.2573 9.64421 5.74271 9.64421 2.27735 7.33397L1.72265 8.16603Z"
                fill="currentColor"
            />
        </svg>
    );
}
SlightCurveDownIcon.displayName = "SlightCurveDownIcon";
