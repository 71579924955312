// This code has been generated via svgr

import React from "react";

export function UpChevronIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M12 9.86328L8 5.96854C8 5.96854 5.52381 8.37957 4 9.86328"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
UpChevronIcon.displayName = "UpChevronIcon";
