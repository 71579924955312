// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement> & {
    iconText: string;
};

export function ItalicsIcon(props: Props) {
    const { iconText, ...rest } = props;
    return (
        <svg viewBox="0 0 32 32" fill="none" {...rest}>
            <text
                x="50%"
                y="55%"
                fontSize="28"
                fontFamily="roboto, serif"
                fontWeight="100"
                fontStyle="italic"
                fill="currentColor"
                textAnchor="middle"
                dominantBaseline="central"
            >
                {iconText}
            </text>
        </svg>
    );
}
ItalicsIcon.displayName = "ItalicsIcon";
