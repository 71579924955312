// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function SharpenIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path
                d="M7.1221 3.60947C7.50112 2.91461 8.49888 2.91461 8.87789 3.60948L13.1934 11.5211C13.5568 12.1875 13.0745 13 12.3155 13H3.68454C2.92548 13 2.44317 12.1875 2.80665 11.5211L7.1221 3.60947Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
        </svg>
    );
}
SharpenIcon.displayName = "SharpenIcon";
