import React from "react";

export function UnlockedItemIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M2.73633 14.3057H8.69727C9.17578 14.3057 9.53125 14.1826 9.76367 13.9365C10.0007 13.695 10.1191 13.319 10.1191 12.8086V8.2832C10.1191 7.77279 10.0007 7.39681 9.76367 7.15527C9.53125 6.91374 9.17578 6.79297 8.69727 6.79297H2.73633C2.26237 6.79297 1.9069 6.91374 1.66992 7.15527C1.4375 7.39681 1.32129 7.77279 1.32129 8.2832V12.8086C1.32129 13.319 1.4375 13.695 1.66992 13.9365C1.9069 14.1826 2.26237 14.3057 2.73633 14.3057ZM2.76367 13.4238C2.59505 13.4238 2.46973 13.3805 2.3877 13.2939C2.31022 13.2119 2.27148 13.0706 2.27148 12.8701V8.22168C2.27148 8.0166 2.31022 7.87533 2.3877 7.79785C2.46973 7.71582 2.59505 7.6748 2.76367 7.6748H8.67676C8.84538 7.6748 8.96842 7.71582 9.0459 7.79785C9.12793 7.87533 9.16895 8.0166 9.16895 8.22168V12.8701C9.16895 13.0706 9.12793 13.2119 9.0459 13.2939C8.96842 13.3805 8.84538 13.4238 8.67676 13.4238H2.76367ZM8.15039 7.25781H9.09375V5.14551C9.09375 4.59863 9.19857 4.13607 9.4082 3.75781C9.61784 3.375 9.89811 3.08561 10.249 2.88965C10.6045 2.68913 10.9919 2.58887 11.4111 2.58887C11.8395 2.58887 12.2269 2.68913 12.5732 2.88965C12.9242 3.08561 13.2044 3.375 13.4141 3.75781C13.6283 4.13607 13.7354 4.59863 13.7354 5.14551V6.58105C13.7354 6.75423 13.7809 6.88639 13.8721 6.97754C13.9678 7.06413 14.0817 7.10742 14.2139 7.10742C14.3415 7.10742 14.4508 7.06641 14.542 6.98438C14.6331 6.90234 14.6787 6.7679 14.6787 6.58105V5.26172C14.6787 4.67383 14.5898 4.15658 14.4121 3.70996C14.2389 3.26335 13.9974 2.89193 13.6875 2.5957C13.3822 2.29492 13.0335 2.06934 12.6416 1.91895C12.2497 1.76855 11.8395 1.69336 11.4111 1.69336C10.9827 1.69336 10.5726 1.76855 10.1807 1.91895C9.79329 2.06934 9.44466 2.29492 9.13477 2.5957C8.82943 2.89193 8.58789 3.26335 8.41016 3.70996C8.23698 4.15658 8.15039 4.67383 8.15039 5.26172V7.25781Z"
                fill="currentColor"
            />
        </svg>
    );
}

UnlockedItemIcon.displayName = "UnlockedItemIcon";
