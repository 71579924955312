import React from "react";

export function ReplaceIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path d="M2 11L4 13L6 11" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M14 5L12 3L10 5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M4 13V4H7.5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M12 4V13H8" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}

ReplaceIcon.displayName = "ReplaceIcon";
