// This code has been generated via svgr

import React from "react";

export function DoubleUpChevronIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 15" fill="none" {...props}>
            <path
                d="M11.75 10.8418L8 7.76579C8 7.76579 5.67857 9.66998 4.25 10.8418"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.75 6.2832L8 3.20719C8 3.20719 5.67857 5.11139 4.25 6.2832"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
DoubleUpChevronIcon.displayName = "DoubleUpChevronIcon";
