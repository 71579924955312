import React from "react";
import { Box, FormField, FormLabel, SearchInput } from "@vp/swan";

type Props = {
    className?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onClearSearch: () => void;
    onSearch: () => void;
    searchTerm: string;
    placeholder: string;
    resetButtonAriaLabel: string;
    searchButtonAriaLabel: string;
};

export const SidebarSearchBar = (props: Props) => {
    const { onChange, onClearSearch, onSearch, searchTerm, placeholder, resetButtonAriaLabel, searchButtonAriaLabel } =
        props;

    return (
        <Box pb={"4"}>
            <FormField>
                <FormLabel visuallyHidden>{placeholder}</FormLabel>
                <SearchInput
                    placeholder={placeholder}
                    accessibleTextForClearButton={resetButtonAriaLabel}
                    accessibleTextForSearchButton={searchButtonAriaLabel}
                    value={searchTerm}
                    onChange={onChange}
                    onClear={onClearSearch}
                    onSearchSubmit={onSearch}
                    onKeyUp={(event: { key: string }) => {
                        if (event.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
            </FormField>
        </Box>
    );
};

SidebarSearchBar.displayName = "SidebarSearchBar";
