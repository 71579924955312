// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function CropIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <path
                d="M4 2V11C4 11.5523 4.44772 12 5 12H14M12 14V5C12 4.44772 11.5523 4 11 4H2"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}
CropIcon.displayName = "CropIcon";
