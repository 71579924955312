import React from "react";
import classNames from "classnames";
import { Box, BoxProps } from "@vp/swan";
import styles from "./PanelContent.module.scss";

type PanelContentProps = BoxProps & {
    children: React.ReactNode;
    className?: string;
};

export const PanelContent = React.forwardRef(({ children, className, ...rest }: PanelContentProps, ref: any) => {
    return (
        <Box ref={ref} className={classNames("studio-panel-content", styles.studioPanelContent, className)} {...rest}>
            {children}
        </Box>
    );
});

PanelContent.displayName = "PanelContent";
