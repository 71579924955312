import React from "react";

export function BackgroundColorIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.45442 0H2C0.895431 0 0 0.895431 0 2V4.45442L4.45442 0ZM0 7V10C0 10.1412 0.0146347
                10.279 0.0424703 10.4119L10.4119 0.0424702C10.279 0.0146347 10.1412 0 10 0H7L0 7ZM12
                2V4.45442L4.45442 12H2C1.70653 12 1.42782 11.9368 1.17675 11.8232L11.8232 1.17675C11.9368
                1.42782 12 1.70653 12 2ZM10 12H7L12 7V10C12 11.1046 11.1046 12 10 12Z"
                fill="currentColor"
            />
        </svg>
    );
}
BackgroundColorIcon.displayName = "BackgroundColorIcon";
