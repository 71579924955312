import React from "react";

export function TrimLineIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" {...props}>
            <rect width="24" height="24" rx="12" fill="#EEF5FB" />
            <rect
                x="19.75"
                y="12.25"
                width="0.5"
                height="15.5"
                rx="0.25"
                transform="rotate(90 19.75 12.25)"
                fill="white"
                stroke="#1774D0"
                strokeWidth="0.5"
                strokeDasharray="1 1"
            />
        </svg>
    );
}
TrimLineIcon.displayName = "TrimLineIcon";
