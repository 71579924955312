// This code has been generated via svgr

import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function ListBulletsIcon(props: Props) {
    return (
        // <svg viewBox="0 0 32 32" fill="none" {...props}>
        //     <path fill="currentColor" d="M13 9h12v2H13zM13 15h12v2H13zM13 21h12v2H13z" />
        //     <path
        //         fillRule="evenodd"
        //         clipRule="evenodd"
        //         d="M9 12a2 2 0 100-4 2 2 0 000 4zM9 18a2 2 0 100-4 2 2 0 000 4zM9 24a2 2 0 100-4 2 2 0 000 4z"
        //         fill="currentColor"
        //     />
        // </svg>
        <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m2.541 0.80469c-1.0235-1e-8 -1.8574 0.83393-1.8574 1.8574 0 1.0235 0.83393 1.8555 1.8574 1.8555 1.0235 1e-7 1.8555-0.83198 1.8555-1.8555 0-1.0235-0.83198-1.8574-1.8555-1.8574zm3.0781 1.0879v1.5h9.7852v-1.5zm-3.0781 4.0156c-1.0235 0-1.8574 0.83393-1.8574 1.8574-6e-8 1.0235 0.83393 1.8555 1.8574 1.8555 1.0235 0 1.8555-0.83198 1.8555-1.8555 0-1.0235-0.83198-1.8574-1.8555-1.8574zm3.0781 1.1562v1.5h9.7852v-1.5zm-3.0781 4.2656c-1.0235 0-1.8574 0.83198-1.8574 1.8555-5.4e-7 1.0235 0.83393 1.8574 1.8574 1.8574s1.8555-0.83393 1.8555-1.8574c-1e-7 -1.0235-0.83198-1.8555-1.8555-1.8555zm3.0781 1.0547v1.5h9.7852v-1.5z"
                fill="currentColor"
            />
        </svg>
    );
}
ListBulletsIcon.displayName = "ListBulletsIcon";
