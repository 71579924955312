import React from "react";

export function SheetHorizontalScrollRightButton(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 10 17" fill="none" {...props}>
            <path
                d="M1 1L8.5 8.5C8.5 8.5 3.85714 13.1429 1 16"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SheetHorizontalScrollRightButton.displayName = "SheetHorizontalScrollRightButton";
