import React from "react";

export function OpacityIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" {...props} fill="none">
            <rect width="3" height="3" fill="currentColor" />
            <rect opacity="0.3" x="8" width="3" height="3" fill="currentColor" />
            <rect opacity="0.5" x="4" y="3" width="3" height="3" fill="currentColor" />
            <rect opacity="0.2" x="12" y="3" width="3" height="3" fill="currentColor" />
            <rect opacity="0.5" x="4" y="9" width="3" height="3" fill="currentColor" />
            <rect opacity="0.2" x="12" y="9" width="3" height="3" fill="currentColor" />
            <rect y="6" width="3" height="3" fill="currentColor" />
            <rect opacity="0.3" x="8" y="6" width="3" height="3" fill="currentColor" />
            <rect y="12" width="3" height="3" fill="currentColor" />
            <rect opacity="0.3" x="8" y="12" width="3" height="3" fill="currentColor" />
        </svg>
    );
}
OpacityIcon.displayName = "OpacityIcon";
