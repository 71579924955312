import React from "react";

export function ValidationIcon(props: React.HTMLAttributes<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 15 12" {...props}>
            <path d="M6.6855 0.857492L0.545219 11.0913C0.30527 11.4912 0.593338 12 1.05971 12H13.3403C13.8067 12 14.0947 11.4912 13.8548 11.0913L7.7145 0.857493C7.48145 0.46909 6.91855 0.469089 6.6855 0.857492Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.20156 8.39998C6.96852 8.39998 6.77716 8.21576 6.7683 7.98289L6.62437 4.19954C6.61192 3.87216 6.87395 3.59998 7.20156 3.59998C7.52918 3.59998 7.79121 3.87216 7.77875 4.19954L7.63482 7.98289C7.62596 8.21576 7.4346 8.39998 7.20156 8.39998ZM7.20156 10.2C7.53293 10.2 7.80156 9.93135 7.80156 9.59998C7.80156 9.26861 7.53293 8.99998 7.20156 8.99998C6.87019 8.99998 6.60156 9.26861 6.60156 9.59998C6.60156 9.93135 6.87019 10.2 7.20156 10.2Z"
                fill="white"
            />
        </svg>
    );
}
ValidationIcon.displayName = "ValidationIcon";
