import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function ChangeProductSizeIcon(props: Props) {
    return (
        <svg viewBox="0 0 19 20" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.4 18.6V1.4H10.6V6H12V1C12 0.447715 11.5523 0 11 0H1C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H8C7.44771 20 7 19.5523 7 19V18.6H1.4Z"
                fill="currentColor"
            />
            <mask id="path-2-inside-1_146_4283" fill="white">
                <rect x="7" y="6" width="12" height="14" rx="1" />
            </mask>
            <rect
                x="7"
                y="6"
                width="12"
                height="14"
                rx="1"
                stroke="currentColor"
                strokeWidth="2.8"
                mask="url(#path-2-inside-1_146_4283)"
            />
        </svg>
    );
}
ChangeProductSizeIcon.displayName = "ChangeProductSizeIcon";
