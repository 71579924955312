import React from "react";

export function ContrastIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <circle cx="8" cy="8.5" r="7.5" fill="white" stroke="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5V14.5Z"
                fill="currentColor"
            />
        </svg>
    );
}
ContrastIcon.displayName = "ContrastIcon";
