import React, { HTMLProps } from "react";
import classnames from "classnames";
import styles from "./LeftSidebar.module.scss";

interface SidebarPanelContainerProps extends HTMLProps<HTMLDivElement> {
    /** Decides whether the sidebar panels should be in a visible state */
    visible: boolean;
}

export function SidebarPanelContainer({ children, className, visible, ...props }: SidebarPanelContainerProps) {
    return (
        <div
            {...props}
            className={classnames(styles.sidebarPanels, { [styles.sidebarPanelsShown]: visible }, className)}
        >
            {children}
        </div>
    );
}

SidebarPanelContainer.displayName = "SidebarPanelContainer";
